import React, {useEffect, useState} from "react";
import { NavLink} from "react-router-dom";
import { Row, Col } from 'reactstrap'
import Logo from './../../../Assets/Images/logo.png';

import { toast } from 'react-toastify';
import Emails from './../../../Assets/Images/verify_email.png';
import {useLocation, useNavigate} from "react-router-dom";
import { useDispatch } from 'react-redux'

import { resendEmailOnConfirmUser } from "../../../utility/service";
import LoginSlider from "../../../Components/LoginSlider";
import { checkMobileWebView } from "../../../utility/helper";
 
  
function EmailVerification() {
    

  let { state } = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(()=>{
    window.scrollTo(0, 0);
    if(state === undefined || state === null || state.Email === undefined ){
      return navigate('/')
    }
  }, [])

  const applyResendEmailOnConfirmUser = async(e)=>{
    e!= undefined && e.preventDefault();
    try{
      let data = {
        email: state.Email
      }
      await resendEmailOnConfirmUser(data, dispatch);
    }catch (err){
      if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
      console.log('this is catch applyResetPassword', err)
    }

  }

  
    return (
      <React.Fragment>
        <div className="container-fluid p-0 signup-page">
        <Row className="m-0">
            <Col className="p-0" lg={6}>
              <LoginSlider />
            </Col>
            <Col className="pt-3 signupScreen" lg={6}>
            
                <div className="signup-wrap " data-aos="zoom-in-up" data-aos-delay="100" data-aos-duration="100">
                  {/* Logo  */}
                  <div className="text-center pb-5 mt-3">
                                                <a 
                                href= {checkMobileWebView()? "#" :"/"}
                                onClick={(e)=>{
                                    if(checkMobileWebView()){
                                        e.preventDefault();
                                        window.ReactNativeWebView.postMessage('formSubmitted');
                                    }
                                }}
                            ><img className="mb-4 pb-1" src={Logo} alt="" /></a>
                    <div>
                      <img className="mb-4 " src={Emails} alt="" />
                    </div>
                    <h5 className="mb-3">Verify your email address</h5>
                    <p>Almost there An email containing verification instructions was sent to <a href="mailto:john@gmail.com">{state!=undefined && state.Email!= undefined && state.Email}</a></p>
                  
                    <div className="mt-4 pt-0 pt-md-2">
                      <p className="mb-0">Didn't receive the email? <a 
                      onClick={(e)=>{
                        e.preventDefault();
                        applyResendEmailOnConfirmUser()
                      }}
                      >Resend email</a></p>
                      <span className="customDevider"></span>
                      <p className="mb-0 mt-1">Already have an account? <NavLink to="/login"><u>Login</u></NavLink></p>
                    </div>
                  </div>

               
                </div>

                {/* copyright content  */}
                <div class="copyright text-center p-3"><p class="m-0">Copyright 2024. © Intello Tags All Rights Reserved</p></div>
              </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
  export default EmailVerification;