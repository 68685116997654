import React, {useEffect, useState} from "react";
import {Container, Row, Col } from 'reactstrap';
import Logo from './../../../../Assets/Images/logo.png';
import { useDispatch } from "react-redux"
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { claimTheReward, postSaveLastScan } from "../../../../utility/service";
import validator from 'validator';
import { useLocation } from "react-router-dom";
import { checkMobileWebView } from "../../../../utility/helper";

function Cart() {

    const navigate = useNavigate();
    const {state} = useLocation();
    const dispatch= useDispatch();
    const [yourName, setYourName] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")
    const [description, setDescription] = useState("")
    const [latLng, setLatLng] = useState({lat: "", lng: ""})
    
    const [yourNameErr, setYourNameErr] = useState(false)
    const [emailErr, setEmailErr] = useState(false)
    const [mobileErr, setMobileErr] = useState(false)
    const [descriptionErr, setDescriptionErr] = useState(false)

    console.log("statestatestate clam", state)
    
    useEffect(()=>{
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async(position) =>{
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                setLatLng({lat: latitude, lng: longitude})
                console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
            })
        }
    }, [])

    const onInputChange = (e)=>{
    
        switch(e.target.name){
            case "yourName":
                setYourName(e.target.value);
                setYourNameErr(false)
            break;
            case "email":
                setEmail(e.target.value);
                setEmailErr(false)
            break;
            case "mobile":
                if(/^\d{0,12}$/.test((e.target.value))){
                    setMobile(e.target.value);
                    setMobileErr(false);
                }
            break;
            case "description":
                setDescription(e.target.value);
                setDescriptionErr(false)
            break;
        }
    }

    useEffect(()=>{
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              console.log(`Latitude  e: ${latitude}, Longitude: ${longitude}`);
            });
          }
    }, [])

    const applyClaim = async (e)=>{

        e.preventDefault();
        toast.dismiss();

        if(yourName!== undefined && validator.isEmpty(yourName.trim())){
            toast.error("Please Enter Your Name", { });
            setYourNameErr(true)
            return;
        }

        if(!validator.isEmail(email, {blacklisted_chars : "+-"})){
            if(email === "") toast.error("Please Enter Email", { });
            else toast.error("Please Enter valid Email", { });
            setEmailErr(true)
            return
        }

        if(mobile ===null ||validator.isEmpty(mobile)){
            toast.error("Please Enter Mobile Number", { });
            setMobileErr(true)
            return;
        }

        if(description!== undefined && validator.isEmpty(description.trim())){
            toast.error("Please Enter Description", { });
            setDescriptionErr(true)
            return;
        }
        
        let claimData = {
            Name: yourName,
            Email: email,
            MobileNumber: mobile,
            Description: description
        } 

        console.log("activateDataactivateData", claimData, navigator)
          
        try{

            let coOrdinates = {
                QRNumber: state.qrCodeDetails.QRNumber,
                lat: latLng.lat,
                long: latLng.lng,
                email: email,
                name: yourName
            } 
            await postSaveLastScan(coOrdinates, dispatch); 
            let res= await claimTheReward(claimData, dispatch); 
            toast("Congratulation, Please check your mail for reward", { });
            navigate('/welcome-qr', {state: {qrCodeDetails: state.qrCodeDetails}});
            console.log("res.HTTPStatusCoderes.HTTPStatusCode", res)
            
            setYourName("");
            setEmail("");
            setMobile("");
            setDescription("");
            
        }catch (err){
            if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
            return;
        }

    }
    
    return (
        <React.Fragment>
            <main className="bg-light qr-screen h-100vh">
                <header className="header px-3 px-sm-4">
                    <div className="container">
                        <nav class="fixed-top1 navbar-expand-lg navbar navbar-light bg-faded">
                                                        <a 
                                href= {checkMobileWebView()? "#" :"/"}
                                onClick={(e)=>{
                                    if(checkMobileWebView()){
                                        e.preventDefault();
                                        window.ReactNativeWebView.postMessage('formSubmitted');
                                    }
                                }}
                            >
                                <img src={Logo} alt="Logo" className="img-fluid mt-0" />
                            </a>
                        </nav>
                    </div>
                </header>
                <section>
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col xl={6}>
                                <div className="mb-2 ">
                                    <h6 className="light-title text-uppercase text-primary">Welcome to</h6>
                                    <h3 class="text-uppercase text-primary">Intello Tags</h3>
                                    <p className="mw-250 font-medium">Claim the Goodies - It's<br /> Yours!</p>
                                </div>
                                <div className="qr-from text-start p-3 p-sm-4">
                                    <div className="white-box">
                                        <h6 className="text-center mb-0 pt-2">Fill the form</h6>
                                        <form className="p-3" onSubmit={applyClaim}>
                                        
                                            <Row className="justify-content-center">
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <label className="form-label">Full Name</label>
                                                        <input 
                                                            type="text" 
                                                            className={'form-control'} 
                                                            name="yourName" 
                                                            value={yourName}
                                                            onChange={onInputChange} 
                                                            placeholder="Enter full name" 
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <label className="form-label">Enter Email id</label>
                                                        <input 
                                                            type="text" 
                                                            className={'form-control'} 
                                                            name="email" 
                                                            value={email}
                                                            onChange={onInputChange} 
                                                            placeholder="Enter email"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <label className="form-label">Mobile</label>
                                                        
                                                        <input
                                                            type="text" 
                                                            className="form-control"
                                                            name="mobile" 
                                                            value={mobile}
                                                            onChange={onInputChange} 
                                                            placeholder="Enter mobile number" 
                                                            />
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <label className="form-label">Description</label>
                                                        
                                                        <textarea
                                                            rows={5}
                                                            type="text" 
                                                            className="form-control"
                                                            name="description" 
                                                            value={description}
                                                            onChange={onInputChange} 
                                                            placeholder="Enter description" 
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <button className="btn btn-primary btn-rounded w-100" type= "submit">Submit</button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </div>
                                </div>
                                
                            </Col>
                        </Row>
                    </Container>
                </section>
            
            </main>
        </React.Fragment>
    );
}
  
  export default Cart;