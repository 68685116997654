export function groupBy(array, property) {
  const grouped = array.reduce((result, item) => {
      (result[item[property]] = result[item[property]] || []).push(item);
      return result;
  }, {});

  return Object.values(grouped);
}

export function magnify(imgID, zoom) {
    let img, glass, w, h, bw;
    img = document.getElementById(imgID);
    /*create magnifier glass:*/
    glass = document.createElement("DIV");
    glass.setAttribute("id", imgID+"glass");
    glass.setAttribute("class", "img-magnifier-glass");
    /*insert magnifier glass:*/
    img.parentElement.insertBefore(glass, img);
    /*set background properties for the magnifier glass:*/
    glass.style.backgroundImage = "url('" + img.src + "')";
    glass.style.backgroundRepeat = "no-repeat";
    glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
    bw = 3;
    w = glass.offsetWidth / 2;
    h = glass.offsetHeight / 2;
    /*execute a function when someone moves the magnifier glass over the image:*/
    glass.addEventListener("mousemove", moveMagnifier);
    img.addEventListener("mousemove", moveMagnifier);
    /*and also for touch screens:*/
    glass.addEventListener("touchmove", moveMagnifier);
    img.addEventListener("touchmove", moveMagnifier);
    function moveMagnifier(e) {
      let pos, x, y;
      /*prevent any other actions that may occur when moving over the image*/
      e.preventDefault();
      /*get the cursor's x and y positions:*/
      pos = getCursorPos(e);
      x = pos.x;
      y = pos.y;
      /*prevent the magnifier glass from being positioned outside the image:*/
      if (x > img.width - (w / zoom)) {x = img.width - (w / zoom);}
      if (x < w / zoom) {x = w / zoom;}
      if (y > img.height - (h / zoom)) {y = img.height - (h / zoom);}
      if (y < h / zoom) {y = h / zoom;}
      /*set the position of the magnifier glass:*/
      glass.style.left = (x - w) + "px";
      glass.style.top = (y - h) + "px";
      /*display what the magnifier glass "sees":*/
      glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
    }
    function getCursorPos(e) {
      let a, x = 0, y = 0;
      e = e || window.event;
      /*get the x and y positions of the image:*/
      a = img.getBoundingClientRect();
      /*calculate the cursor's x and y coordinates, relative to the image:*/
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /*consider any page scrolling:*/
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return {x : x, y : y};
    }
  }

export function getGeoLocationPermission(){

  return new Promise((resolve, rejected)=>{
    if ("geolocation" in navigator) {
      // Try to get the current position
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Success callback - location is enabled
          resolve(true)
        },
        (error) => {
          // Error callback - check for specific error code
          if (error.code === error.PERMISSION_DENIED) {
            // Permission denied, location services are likely disabled
            alert("Please enable location services.");
            rejected(true)
            // window.location.reload();
          } else {
            console.error("Error occurred: ", error.message);
            rejected(true)
            // alert("Could not determine location. Please check your location settings.");
          }
        }
      );
    }else{
      rejected(false)
    }
  })
}

const isMobileWebView = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const isAndroidWebView =
    /wv|WebView/.test(userAgent) && /Android/.test(userAgent);
  const isIOSWebView =
    /\b(iPhone|iPod|iPad)\b/.test(userAgent) &&
    /AppleWebKit(?!.*Safari)/.test(userAgent);
  const isReactNativeWebView = typeof window.ReactNativeWebView !== 'undefined';

  return isAndroidWebView || isIOSWebView || isReactNativeWebView;
};

export function checkMobileWebView(){
  if (isMobileWebView()) {
    return true;
  } else {
    return false;
  }
}


export const includedRoutes = [
  "qrtoaction",
  "tag",
  "edit-tag",
  "activate",
  "tag-activated",
  "membership-expired",
  "welcome-qr",
  "claim-reward",
  "medical-history",
];

// Function to check if any included route is part of the URL
export function checkIsSecondWebview(url) {
  const segments = url.split('/'); // Split the URL into parts
  return includedRoutes.some((route) => segments.includes(route));
}