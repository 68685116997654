import React, { useState }  from "react";
import HeaderFooter from "../../HeaderFooter";
import {Col, Row, Breadcrumb, BreadcrumbItem,  Container,  } from 'reactstrap';
import PriceTable from './../../Components/PriceTable';
import freemeum from './../../Assets/Images/freemium.png';
import Premium from './../../Assets/Images/premium.png';
import Platinium from './../../Assets/Images/platinum.png';
import { checkMobileWebView } from "../../utility/helper";

export default ()=>{
const freeData = ['Normal Calls', 'Normal Text', 'Normal Email', 'Encrypted Data', 'Normal Tag', '1 Year Validity']
const premiumData = ['Encrypted Calls', 'Encrypted Text', 'Encrypted Email', 'Encrypted Data', 'Designer Tag', 'DND', 'Scan History', 'Lifetime Discount', 'Tag Replacement', '3 Year Validity']
const platiniumData = ['Encrypted Calls', 'Encrypted Text', 'Encrypted Email', 'Encrypted Data', 'Designer Tag', 'DND', 'Scan History', 'Lifetime Discount', 'Tag Replacement', '5 Year Validity']
    return (
        <React.Fragment>
            <HeaderFooter>
            <main className="main">
                <section className="p-0">
                    <div  className="page-title">
                        <Container>
                            <h2 className="text-center" data-aos-offset="200" data-aos="zoom-in-up" data-aos-delay="100">Membership</h2>
                        </Container>
                    </div>
                </section>
                <section className="pt-4 mt-2 pb-3" data-aos-offset="200" data-aos="fade-in-right" data-aos-delay="100">
                    <Container>
                        <Breadcrumb>
                            <BreadcrumbItem>                            <a 
                                href= {checkMobileWebView()? "#" :"/"}
                                onClick={(e)=>{
                                    if(checkMobileWebView()){
                                        e.preventDefault();
                                        window.ReactNativeWebView.postMessage('formSubmitted');
                                    }
                                }}
                            >Home</a></BreadcrumbItem>
                            <BreadcrumbItem active>Membership Plan</BreadcrumbItem>
                        </Breadcrumb>
                    </Container>
                </section>
                <section className="pt-0">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={10} >
                                <Row>
                                    <Col sm={6} md={4} lg={4} className="mb-3">
                                        <PriceTable image={freemeum} data={freeData} action="/products" />
                                    </Col>
                                    <Col sm={6} md={4} lg={4}  className="mb-3">
                                        <PriceTable image={Premium} data={premiumData} action="/products" />
                                    </Col>
                                    <Col sm={6} md={4} lg={4}  className="mb-3">
                                        <PriceTable image={Platinium} data={platiniumData} action="/products" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
                
            </main>
           
            </HeaderFooter>
        </React.Fragment>
    )
} 