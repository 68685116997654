import React, {useEffect, useState} from "react";
import { Row, Col } from 'reactstrap'

 import Logo from './../../../Assets/Images/logo.png';
 import EyeOff from './../../../Assets/Images/eye-off.png';
 import Eye from './../../../Assets/Images/eye.png';

import { useDispatch } from 'react-redux'
import validator from 'validator'; 
import { useNavigate , NavLink, useSearchParams} from 'react-router-dom'
import { toast } from 'react-toastify';
import classNames from 'classnames';

import { resetPassword } from "../../../utility/service";
import LoginSlider from "../../../Components/LoginSlider";
import { checkMobileWebView } from "../../../utility/helper";
  
function ResetPassword() {

  const dispatch = useDispatch()
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const [token] = React.useState(
      searchParams.get("token")
  );
  const [email] = React.useState(
      searchParams.get("email")
  );

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordNameErr, setPasswordNameErr] = useState(false)
  const [consfirmfirstNameErr, setConsfirmfirstNameErr] = useState(false)
  const [isPasswordMaskedOPen, setIsPasswordMaskedOPen] = useState(true);
  const [isConfirmPasswordMaskedOPen, setIsConfirmPasswordMaskedOPen] = useState(true);
  
  useEffect(()=>{
    if(token === null || email === null) navigate(-1)
  }, []);

  const applyResetPassword =async (e) =>{
        
    e.preventDefault();
    toast.dismiss()
    if(!validator.isLength(password, {min: 8, max: 14})){
      if(password === "") toast.error("Please Enter New Password", { });
      else toast.error("Password length should be minimum 8 char and maximum 14 char", { });
      setPasswordNameErr(true)
      return;
    }

    if(validator.isEmpty(confirmPassword)){
      toast.error("Please Enter Confirm Password", { });
      setConsfirmfirstNameErr(true)
      return;
    }else if(password !== confirmPassword){
      toast.error("Password and confirmPassword should be same", { });
      setConsfirmfirstNameErr(true)
      return;
    } 

    let resetPasswordDATA = {
      password: password,
      confirmPassword: confirmPassword,
      email: email,
      token: token,
    }

    try{
      console.log('this is try applyResetPassword', "try")
      await resetPassword(resetPasswordDATA, dispatch);
      navigate('/password-changed')
    }catch (err){
      if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
      console.log('this is catch applyResetPassword', err)
    }
  }

  const onInputChange = (e)=>{

    switch(e.target.name){
      case "password":
        setPassword(e.target.value);
        setPasswordNameErr(false)
        break;
      case "confirmPassword":
        setConfirmPassword(e.target.value);
        setConsfirmfirstNameErr(false)
        break;
    }
  }
  
  
    return (
      <React.Fragment>
        <div className="container-fluid p-0 signup-page">
        <Row className="m-0">
            <Col className="p-0" lg={6}>
              <LoginSlider />
            </Col>
            <Col className="pt-3 signupScreen" lg={6}>
                <div className="signup-wrap ">
                  {/* Logo  */}
                  <div className="text-center pb-5 mt-3" data-aos="zoom-in-up" data-aos-delay="100" data-aos-duration="100">
                                                <a 
                                href= {checkMobileWebView()? "#" :"/"}
                                onClick={(e)=>{
                                    if(checkMobileWebView()){
                                        e.preventDefault();
                                        window.ReactNativeWebView.postMessage('formSubmitted');
                                    }
                                }}
                            ><img className="mb-4 pb-1" src={Logo} alt="" /></a>
                    <h5>Reset Password</h5>
                    <form className=" pb-5 mb-2 mb-md-0 pt-3" onSubmit={applyResetPassword}>
                      <div className="mb-3 password-field">
                          <input 
                            // type={isPasswordMaskedOPen ?'password': "Text"} 
                            // name={'password'} 
                            // placeholder="New Password" 
                            // className={'form-control'} 
                            
                            value={password} 
                            onChange={onInputChange} 
                            type={isPasswordMaskedOPen ?'password': "Text"} 
                            name="password" 
                            className={classNames('form-control', { "form-error": passwordNameErr })} placeholder="New Password"
                          />
                          <span onClick={()=>setIsPasswordMaskedOPen(!isPasswordMaskedOPen)}>
                              <img src={isPasswordMaskedOPen ?Eye: EyeOff} alt="Eye" />
                              {/* <img src={EyeOff} className="d-none" alt="Eye" /> */}
                          </span>
                      </div>

                      <div className="mb-3 password-field">
                          <input 
                            // type={isConfirmPasswordMaskedOPen ?'password': "Text"} 
                            // name={'password'} 
                            // placeholder="Confirm Password" 
                            // className={'form-control'} 

                            value={confirmPassword} 
                            onChange={onInputChange} 
                            type={isConfirmPasswordMaskedOPen ?'password': "Text"} 
                            name="confirmPassword" 
                            className={classNames('form-control', { "form-error": consfirmfirstNameErr })} 
                            placeholder="Confirm Password"
                          />
                          <span onClick={()=>setIsConfirmPasswordMaskedOPen(!isConfirmPasswordMaskedOPen)}>
                              <img src={isConfirmPasswordMaskedOPen ?Eye: EyeOff} alt="Eye" />
                              {/* <img src={EyeOff} className="d-none" alt="Eye" /> */}
                          </span>
                      </div>
                      <button type="submit"  className="btn btn-primary w-100" >Reset</button>
                    
                      <div className="text-center mt-4 pt-2">
                          Already have an account? <NavLink to="/registration"><u>Login</u></NavLink>
                      </div>
                  </form>
                  </div>
                </div>
                {/* copyright content  */}
                <div class="copyright text-center p-3"><p class="m-0">Copyright 2024. © Intello Tags All Rights Reserved</p></div>
              </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
  export default ResetPassword;