import { createSlice } from '@reduxjs/toolkit'
import { BASE_URL, GET_USER_PROFILE_DETAILS } from '../../utility/apiRoutes'
import { getData } from '../../utility/rootService'

const initialState = {
  details: {
    Name: "",
    ToEmail: "",
    ToMobile: "",
    Description: "",
    MHYStatus: 0,
    IsCardAdded: 0,
    UserId: "",
    Usertype: "",
    SecurityQ1: "",
    Answer1: "",
    SecurityQ2: "",
    Answer2: "",
    SecurityQ3: "",
    Answer3: "",
    IsPasswordChanged: false,
    IsProfileConfirmed: false,
    IsDNDON: false,
    IsStartVideoDone: false,
    FirstName: "",
    LastName: "",
    Gender: "",
    Email: "",
    DOB: "",
    Address: "",
    Phone_Mobile: "",
    DepartmentID: "",
    DepartmentName: "",
    UserTypeID: "",
    EmpID: "",
    Title: "",
    SpouseFirstName: "",
    SpouseLastName: "",
    SpouseEmail: "",
    SpouseDOB: "",
    Flag: "",
    UserProfilePath: "",
    PreferredTimeZone: "",
    IsSpousePartOfPlan: false,
    EmpCode: "",
    IsStartATeamTermsRead: false,
    IsPowerGoalVideoSeen: false,
    DependentUserID: "",
    IsSpouseEmailChanged: false,
    IsSpousedProfileConfirmed: false,
    RoleId: "",
    Phone_LandLine: "",
    ID: "",
    TenantID: "",
    CreatedDate: "",
    ModifiedDate: "",
    CreatedBy: "",
    ModifiedBy: "",
    Active: "",
    TextMessage: "",
    pinCode: ""
},
  isResGet: false,
}

export const profile = createSlice({
  name: 'profile',
  initialState: { ...initialState },
  reducers: {
    setProfile: (state, action) => {
      console.log('this is our login submit data >>', action)
      return {...state, details: {...action.payload}, isResGet: true}
    },
    resetProfile: (state, action)=>{
      return {...initialState}
    },
    updateProfile: (state, action)=>{
      return {...state, details: {...state.details, ...action.payload.pinCode}}
    },
    
  }
})

// Action creators are generated for each case reducer function
export const { setProfile, resetProfile, updateProfile } = profile.actions

const profileFetch =async (ID, dispatch) =>{
    
    try{
      
    //   const data = await JSON.parse(localStorage.getItem('auth'))
    let url=""
    console.log("profileFetchprofileFetch 1", ID)
    //   let url= BASE_URL+GET_USER_PROFILE_DETAILS+`id=${data.ID}`;
      if(ID !== ""){
        url= BASE_URL+GET_USER_PROFILE_DETAILS+`id=${ID}`;
      }
     
      let headerConfig = {
        "Content-Type": "application/json"
      }

      const res =await getData({url, headerConfig}, dispatch)

    
    return res;
    
  }catch (err){

    console.log("this is a profile catch")
    throw (err)
  }

}

export const profileDispatch = (ID) => {

  return async(dispatch, getState) => {
    try{
        const res =await profileFetch(ID, dispatch) 
        console.log("profileFetchprofileFetch", res)
        dispatch(setProfile(res.Success[0]))
        localStorage.setItem("profile", JSON.stringify({
          ...res.Success
        }))
      }catch (err){
    }
  }
}

export default profile.reducer