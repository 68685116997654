import React, {useEffect, useState} from "react";
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Row, Col, Container, NavLink } from 'reactstrap'
import Header from '../../../Components/Header';
import Image from './../../../Assets/Images/welcome.png'
import { useDispatch, useSelector } from 'react-redux'
import { setScrollToOnlineStoreDispatch } from "../../Home/Slices/scrollManagment.slice";
import WelcomeAnimation from '../../../Components/WelcomeAnimation';
import { checkOrderConfirmation, checkOrderConfirmationQra } from "../../../utility/service";
import { toast } from 'react-toastify';
import { setActiveTabNavigarion } from "../../../Store/Combined.reducer.js/CommanReducer/tabNavigation.slice";

function ConfirmationQra() {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();
  let [orderInfo, setOrderInfo] = React.useState({
    isOrderSuccess: false,
    info: {},
    isResGet: false
  });
  const scrollManagment= useSelector(state=>state.scrollManagment);

  const [orderid] = React.useState(
    searchParams.get("orderid")
  );
  
  useEffect(()=>{
    if(orderid === null) {
      navigate('/');
    }else{

      async function checkIsOrderConfirmed(){

        let uId= "";
        let localData = await JSON.parse(localStorage.getItem('profile'));
        if(localData){
          uId= localData[0].UserId
        }else{
          uId= await localStorage.getItem("userId");
        }
        
        console.log("payload 2", uId)

        let dataobje = {
          UserID: uId,
          InvoiceNumber: orderid.trim()
        } 
    
        try{
          let res= await checkOrderConfirmationQra(dataobje, dispatch);

          if(res.Success.length === 0){
            setOrderInfo({
              isOrderSuccess: false,
              info: {},
              isResGet: true
            })
          }else{
            setOrderInfo({
              isOrderSuccess: true,
              info: res.Success[0],
              isResGet: true
            })
          }
        }catch (err){
          toast.dismiss();
          if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
          
        }
      }
      checkIsOrderConfirmed()
    }

  }, []);

  const getInfoView= ()=>{
    if(orderInfo.isResGet)
    return <React.Fragment>
      <h5>Your Order is {orderInfo.isOrderSuccess? "Confirmd!!": "Failed!!"}</h5>
        {orderInfo.isOrderSuccess && <p className="mb-3 mt-2 font-medium">Thanks for your Order</p>}
        {orderInfo.isOrderSuccess && <p className="mb-0">Order id: {orderid}</p>}
        {/* {orderInfo.isOrderSuccess && <p className="mb-2">Total Amount: INR { orderInfo.info.TotalAmount }</p>} */}
    </React.Fragment>
  }


  return (
    <React.Fragment>
          <Header />
        {/* <div className=" bg-light mh-100vh v-center-content"> */}
          <Container>
            <Row className="m-0 justify-content-center">
              <Col className="pt-4 pb-4 pt-md-5 pb-md-5" lg={6} data-aos="flip-right" data-aos-delay="200" data-aos-duration="200">
                <div className="white-box text-center">
                  <img src={Image} alt="welcome" />
                  <div className="mw-450 pt-4 pb-4">
                    {getInfoView()}
                    <div className="mb-3">
                      <NavLink href="#" className="text-primary" onClick={()=>{
                         navigate('/profile');
                         dispatch(setActiveTabNavigarion("summery"));
                      }}><u>Go to Order Summery!</u></NavLink>
                    </div>
                    <button className="btn btn-primary mt-2" onClick={()=>{
                        navigate('/');
                        dispatch(setScrollToOnlineStoreDispatch(scrollManagment.scrollToOnlineStore+1));
                      }}>Continue Shopping</button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          
          <WelcomeAnimation />
        {/* </div> */}
    </React.Fragment>
  );
}

export default ConfirmationQra;