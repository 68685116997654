import React , {useState, useRef} from "react";
import validator from 'validator';
import { toast } from 'react-toastify';
import { Row, Col,Container, Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem, Nav, NavItem, NavLink, TabContent, TabPane,} from 'reactstrap';
import SupportHelp from './../../Assets/Images/support_help.jpg';
import YourSuggetion from './../../Assets/Images/support_suggestion.jpg';
import  HeaderFooter from './../../HeaderFooter';
import { sendCommanEmail } from "../../utility/service";
import { useDispatch } from 'react-redux';

function Career() {
    
    const dispatch = useDispatch()
    const [isActiveTab, setIsActiveTab] = useState('1');
    const [open, setOpen] = useState('1');

    const toggle = (id) => {
        if (open === id) {
        setOpen();
        } else {
        setOpen(id);
        }
    };

    const setTab = (id) => {
        setIsActiveTab(id);
        setFullName("");
        setEmail("");
        setMobileNumber("");
        setquery("");
    }

    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("")
    const [query, setquery] = useState("")

    const onInputChange = (e)=>{
    
        switch(e.target.name){
          case "fullName":
            setFullName(e.target.value);
            break;
          case "email":
            setEmail(e.target.value);
            break;
          case "mobileNumber":
            if(/^(?:\+?\d{0,15}|\d{0,16})$/.test((e.target.value))){
                setMobileNumber(e.target.value);
            }
            break
        case "query":
            setquery(e.target.value);
            break;
    }
    }

    const applySendEmail = async (e)=>{

        e.preventDefault();
        toast.dismiss()
       
        if(validator.isEmpty(fullName.trim())){
          toast.error("Please Enter First Name", { });
          return;
        }else if(!validator.isLength(fullName.trim(), {min: 1, max: 25})){
          toast.error("First Name length should be minimum 3 char and maximum 25 char", { });
          return;
        }
    
        if(!validator.isEmail(email, {blacklisted_chars : "+-"})){
          if(email === "") toast.error("Please Enter Email", { });
          else toast.error("Please Enter valid Email", { });
          return
        }
        
        if(validator.isEmpty(mobileNumber)){
            toast.error("Please Enter Phone Number", { });
            return;
        }

        if(validator.isEmpty(query.trim())){
            toast.error("Please Enter query Name", { });
            return;
        }else if(!validator.isLength(query.trim(), {min: 5})){
            toast.error("Query length should be minimum 5 char", { });
            return;
        }
    
        let sendEmailData = {
            FullName: fullName.trim(), 
            UserEmail: email,
            Mobile: mobileNumber,
            Subject: isActiveTab == 1 ? "Contact our Helpdesk, We are here to help you!": "Your Suggestions",
            Query: query,
            EmailFor: isActiveTab == 1 ? 1: 2,
        } 
    
        try{
            await sendCommanEmail(sendEmailData, dispatch); 
            toast("Email Sent Successfully", { });
            setFullName("");
            setEmail("");
            setMobileNumber("");
            setquery("");
        }catch (err){
          if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
        }
    }

    return (
      <React.Fragment>
          <HeaderFooter>
           <div className=" main-content">
                <section className="support-page">
                    <Container>
                        <Nav tabs className="justify-content-center pt-0 pb-3" data-aos="zoom-in-down"  data-aos-duration="500">
                            <NavItem>
                                <NavLink
                                    className={isActiveTab === '1' && 'active'}
                                    onClick={event => setTab('1')}
                                >
                                    Contact us
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={isActiveTab === '2' && 'active'}
                                    onClick={event => setTab('2')}
                                >
                                    Your Suggestions
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={isActiveTab === '3' && 'active'}
                                    onClick={event => setTab('3')}
                                >
                                    FAQ
                                </NavLink>
                            </NavItem>
                                
                        </Nav>
                        <TabContent activeTab={isActiveTab}>
                            <TabPane tabId={isActiveTab} className="">
                            <Row className="justify-content-center">
                                <Col lg={10} xxl={9} >
                                {isActiveTab === '1' &&
                                <Row className="justify-content-center">
                                    <Col sm={12} md={12} className="mb-4">
                                        <h4 className="text-center mb-3" data-aos="zoom-in"  data-aos-duration="500">Contact our Helpdesk, We are here to help you!</h4>
                                        <p className="text-center center-content" data-aos="zoom-in"  data-aos-duration="500">Couldn't find the answer to your question in FAQ section? Please contact our customer support using the form below.</p>
                                    </Col>
                                    <Col lg={6} xxl={6} className="d-md-block d-none">
                                        <div className="px-0 px-md-2" data-aos="fade-right" data-aos-duration="500">
                                            <div className="image-wrap rounded">
                                                <img src={SupportHelp} className="w-100" alt="Career" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} xxl={6} className="" >
                                        <div className="px-0 px-md-2" data-aos="fade-left" data-aos-duration="500">
                                            <form className="" onSubmit={(e)=>applySendEmail(e)}>
                                                <Row className="justify-content-center">
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <label className="form-label">Full Name</label>
                                                            <input 
                                                                type="text" 
                                                                className={'form-control'} 
                                                                placeholder="Enter your full name" 
                                                                
                                                                value={fullName} 
                                                                onChange={onInputChange} 
                                                                name="fullName"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <label className="form-label">Email</label>
                                                            <input 
                                                                type="text" 
                                                                className={'form-control'} 
                                                                placeholder="Enter your email"
                                                                
                                                                value={email} 
                                                                onChange={onInputChange} 
                                                                name="email"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <label className="form-label">Mobile</label>
                                                            
                                                            <input
                                                                type="text" 
                                                                className="form-control"
                                                                placeholder="Enter your mobile number" 
                                                                
                                                                value={mobileNumber} 
                                                                onChange={onInputChange} 
                                                                name="mobileNumber"
                                                            
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <label className="form-label">Query</label>
                                                            
                                                            <textarea
                                                                rows={5}
                                                                type="text" 
                                                                className="form-control"
                                                                placeholder="Enter your query" 

                                                                value={query} 
                                                                onChange={onInputChange} 
                                                                name="query"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <div className="mb-1 pt-2">
                                                            <button type="submit" className="btn btn-primary me-3" onClick={applySendEmail}>Submit</button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </div>
                                    </Col>
                                </Row>
                                }
                                
                                
                                
                                
                                {isActiveTab === '2' &&
                                    <Row className="justify-content-center">
                                        <Col sm={12} md={12} className="mb-4">
                                            <h4 className="text-center mb-3" data-aos="zoom-in"  data-aos-duration="500">Your Suggestions</h4>
                                            <p className="text-center center-content" data-aos="zoom-in"  data-aos-duration="500">We'd love to her valuable suggestions.<br />please send us the details and we'll get back to you as soon as we can.</p>
                                        </Col>
                                    <Col lg={6} xxl={6}  className="">
                                        <div className="px-0 px-md-2" data-aos="fade-right" data-aos-duration="500">
                                                <form className="">
                                                <Row className="justify-content-center">
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <label className="form-label">Full Name</label>
                                                            <input 
                                                                type="text" 
                                                                className={'form-control'} 
                                                                placeholder="Enter your full name" 

                                                                value={fullName} 
                                                                onChange={onInputChange} 
                                                                name="fullName"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <label className="form-label">Email</label>
                                                            <input 
                                                                type="text" 
                                                                className={'form-control'} 
                                                                placeholder="Enter your email"

                                                                value={email} 
                                                                onChange={onInputChange} 
                                                                name="email"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <label className="form-label">Mobile</label>
                                                            
                                                            <input
                                                                type="text" 
                                                                className="form-control"
                                                                placeholder="Enter your mobile number" 
                                                            
                                                                value={mobileNumber} 
                                                                onChange={onInputChange} 
                                                                name="mobileNumber"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <label className="form-label">Query</label>
                                                            
                                                            <textarea
                                                                rows={5}
                                                                type="text" 
                                                                className="form-control"
                                                                placeholder="Enter your query" 

                                                                value={query} 
                                                                onChange={onInputChange} 
                                                                name="query"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <div className="mb-1 mt-2">
                                                            <button type="submit" className="btn btn-primary me-3" onClick={applySendEmail}>Submit</button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </div>
                                    </Col>
                                    <Col lg={6} xxl={6} className="d-md-block d-none">
                                        <div className="px-0 px-md-2" data-aos="fade-left" data-aos-duration="500">
                                            <div className="image-wrap rounded">
                                                <img src={YourSuggetion} className="w-100" alt="Career" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                }
                               
                               
                                {isActiveTab === '3' &&
                                        
                                    <div>
                                        <div className="mb-4" data-aos="zoom-in" data-aos-duration="500">
                                                <h4 className="text-center" >Frequently Ask Question</h4>
                                            </div>
                                            <div className="pt-2"data-aos="zoom-in"  data-aos-duration="500" >
                                                <Accordion className="faqAccordion" open={open} toggle={toggle}>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="1" className="flex-column ps-4 pe-4">
                                                            I'm not able to scan the QR Code, what shall I Do?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="1">
                                                            <p>Open the camera or any QR Reader in your smartphone and hold the camera at least 4 inches away from the QR code on the tag. If you hold still, and if you have sufficient light, then the lens should quickly focus on the QR code and capture the connecting link to the owner.</p>
                                                            <p>Sometimes the QR code reader will focus on high-contrast items in the background, in such cases Re-position the smartphone and tag so that there is no high-contrast object competing for focus.</p>
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="2" className="flex-column ps-4 pe-4">
                                                        I'm having difficulty receiving alerts by Email, WhatsApp & Call. What could be the problem?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="2">
                                                        <p>Login to your account by using user id & password. Ensure that all NOTIFICATION ALERTS are enabled. Also Check the DND at our website or mobile apps.</p>
                                                        <p>It is also possible that your email service provider is placing Intello Tags emails into your spam folder. Please check your spam folder and mark it non spam so that it will reflect in your inbox.</p>
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="3" className="flex-column ps-4 pe-4">
                                                        Do your tags have a battery?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="3">
                                                            <p>No, our tags utilize passive scanning technology, and do not contain any electronics or battery.</p>
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="4" className="flex-column ps-4 pe-4">
                                                        How do I activate IntelloTag, after receiving?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="4">
                                                            <p>Please refer to our <a href="#">Instruction Manual</a> which comes along with the Tag. In a nutshell, you should first <a href="/login">log into your account</a> (or create a new account). Then, simply scan the tag with a QR code reader/scanner/ Smartphone Camera. This should bring you to the Intello Tag information form, fill all the details and submit, now you are ready to use your Intello Tags. </p>
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="5" className="flex-column ps-4 pe-4">
                                                        Can I rely upon the accuracy of live location after scanning the Tag?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="5">
                                                        When your tag is scanned by a finder, location information is requested from the browser on the finder’s smartphone. However, browsers use various mechanisms to determine location, including Wi Fi and GPS, depending upon what’s available under the circumstances. Therefore, location accuracy is dependent upon the location accuracy that can be provided by the browser/smartphone. On average, we have found that location accuracy is very good, but this cannot be guaranteed under all circumstances.
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="6" className="flex-column ps-4 pe-4">
                                                        Can you supply us customized Tags with our company logo?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="6">
                                                        We are happy to supply Customized Tags with your company name in association with Intello Tags. For more details kindly mail us at <a href="mailto:hello@Intellotags.com">hello@Intellotags.com</a>
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="7" className="flex-column ps-4 pe-4">
                                                        We have concerns over the durability of the Tags. How durable are they?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="7">
                                                        All Intello Tags are manufactured with the unique codes in large printing presses using a multi-layer manufacturing process and are backed with one of the most advanced permanent adhesives on the market. All our Tags are very durable and weather resistant.
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="8" className="flex-column ps-4 pe-4">
                                                        What is so special about Intello Tags?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="8">
                                                            <p>All our Intello Tags are having unique and multiple features which can provide you an additional security to all your valuables & belongings. Special features of the tags are…</p>
                                                            <div>
                                                                <Row>
                                                                    <Col xs={11} md={4}><strong>Simple Setup</strong></Col>
                                                                    <Col xs={1}>-</Col>
                                                                    <Col xs={12} md={7}>Buy Tag, Scan QR, Fill Details and ready to use.</Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xs={11} md={4}><strong>Smartphone Compatible</strong></Col>
                                                                    <Col xs={1}>-</Col>
                                                                    <Col xs={12} md={7}>Compatible with any QR Code Reader on any Smartphone.</Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xs={11} md={4}><strong>Robust communication</strong></Col>
                                                                    <Col xs={1}>-</Col>
                                                                    <Col xs={12} md={7}>Get notified instantly by Call, Email & WhatsApp</Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xs={11} md={4}><strong>Location Enabled</strong></Col>
                                                                    <Col xs={1}>-</Col>
                                                                    <Col xs={12} md={7}>Captures GPS Location upon tag scan by First responder.</Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xs={11} md={4}><strong>Go Global</strong></Col>
                                                                    <Col xs={1}>-</Col>
                                                                    <Col xs={12} md={7}>Deliver & Serve globally. Multiple language friendly website.</Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xs={11} md={4}><strong>Weather-proof</strong></Col>
                                                                    <Col xs={1}>-</Col>
                                                                    <Col xs={12} md={7}>UV Protected, Water Proof, No wear & tear</Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xs={11} md={4}><strong>Maintenance - Free</strong></Col>
                                                                    <Col xs={1}>-</Col>
                                                                    <Col xs={12} md={7}>No Recharge, No Batteries, No Repair Needed.</Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xs={11} md={4}><strong>Get Rewarded</strong></Col>
                                                                    <Col xs={1}>-</Col>
                                                                    <Col xs={12} md={7}>Be the first citizen, Help other and get rewards through our benevolent club</Col>
                                                                </Row>
                                                            </div>
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="9" className="flex-column ps-4 pe-4">
                                                        How reward program work and who pays for it?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="9">
                                                            <p>Intello Tags has three clubs and each club runs a separate Reward Program. We took this initiative to motivate those people who helped our clients or society and come forward in the difficult time.</p>
                                                            <p>Good Human beings don't need monetary incentive to help someone but being a curtesy we deliver them a special gift to encourage their good deed.</p>
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="10" className="flex-column ps-4 pe-4">
                                                        Is there any warranty for Intello Tags?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="10">
                                                            <p>All Intello Tags come with lifetime warranty other than Automobile Tags.</p>
                                                            <ul>
                                                                <li>First year replacement is free, only shipping charges has to be borne by the customer.</li>
                                                                <li>50% discount for the replacement tag after the first year till last uses.</li>
                                                                <li>Intello Tags can be replaced as many times as you deem necessary - driven by loss of tag, normal usage wear, damage to the tag, etc..</li>
                                                            </ul>
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="11" className="flex-column ps-4 pe-4">
                                                        How do I request replacement under warranty?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="11">
                                                        Simply email us on <a href="mailto:hello@Intellotags.com">hello@Intellotags.com</a> with your specific request, and we'll respond with further instructions.
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="12" className="flex-column ps-4 pe-4">
                                                        How secure is your system?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="12">
                                                        Our system has many advanced level security features including hardware authentication tokens, row level encryption as well as traditional role based security methods. When an item is registered, the privacy options can be set by the user. This allows any item to be set from full anonymous access through to owner only access.
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="13" className="flex-column ps-4 pe-4">
                                                        How can I locate my tagged stuff after lost or stolen?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="13">
                                                        Intello Tags are designed to provide property finders with a way to contact you if/when they find your property and scan your tag. Intello Tags implements a passive scanning system, which contains no electronics, has no battery, and therefore lasts a lifetime. But it does require a property finder to scan the tag. Therefore, Intello Tags are not capable of actively locating lost or stolen items without the help of a Good human being who makes an effort to contact you. 
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="14" className="flex-column ps-4 pe-4">
                                                        Where I can see my order?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="14">
                                                            <p>To access your profile, simply enter your unique User ID and Password in the provided fields on the login page.</p>
                                                            <p>After logging in, locate the left-hand toggle menu. Click on the "My Order" tab within this menu.</p>
                                                            <p>In the "My Order" section, you will find a comprehensive list of all your previous orders. This provides a convenient overview of your order history.</p>
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="15" className="flex-column ps-4 pe-4">
                                                        How to edit my tag?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="15">
                                                            <ul>
                                                                <li>Log in to the Intello Tags app using your dedicated User ID and Password, or visit <a href="www.intellotags.com">www.intellotags.com</a> for access.</li>
                                                                <li>After successful login, find the toggle menu on the left side of the screen.</li>
                                                                <li>Click on "Activated Tags" within the menu to manage your tags.</li>
                                                                <li>Select the desired tag from the available QR codes.</li>
                                                                <li>Click "More Details" to access additional tag information.</li>
                                                                <li>Use "Edit Profile" to modify associated details.</li>
                                                                <li>Save your changes by clicking "Submit."</li>
                                                            </ul>
                                                            <p>This streamlined process empowers you to efficiently update and manage your Intello Tags' information.</p>
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="16" className="flex-column ps-4 pe-4">
                                                        Can I gift my tag to someone?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="16">
                                                            <p>Absolutely, you have the option to purchase and gift a tag to another individual. After receiving the gifted tag, the individual will activate it using their own personal information. This activation process is essential for them to proceed.</p>
                                                            <p>Once the tag is activated, your friend or family member can apply the Intello Tags to their possessions or vehicles. This empowers them to utilize the benefits of Intello Tags.</p>
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="17" className="flex-column ps-4 pe-4">
                                                        How to change my account password?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="17">
                                                            <p>To get started, log in to your Intello Tags account using your unique User ID and Password. Alternatively, you can also log in by visiting the website <a href="www.intellotags.com">www.intellotags.com</a>.</p>
                                                            <p>Upon successful login, find the toggle menu located on the left-hand side. Click on the "Change Password" tab available in this menu.</p>
                                                            <p>After selecting "Change Password," a new page will open. Here, you need to enter your preferred new password into the provided field.</p>
                                                            <p>Once you've entered your new password, proceed by clicking the "Submit" button. This action confirms your password change request and updates your account's login credentials accordingly.</p>
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="18" className="flex-column ps-4 pe-4">
                                                            What if I forgot my Password?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="18">
                                                        <ul>
                                                        <li>If you've forgotten your password, our straightforward recovery process has you covered.</li>
                                                            <li>Click "Forgot Password" on the login page.</li>
                                                            <li>Enter your registered email and submit.</li>
                                                            <li>Check your inbox for a reset link.</li>
                                                            <li>Click the link to set a new password.</li>
                                                            <li>Return to the login page and access your account using your User ID and the new password.</li>
                                                        </ul>
                                                            <p>This process ensures a smooth account recovery, even if you've forgotten your password.</p>
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="19" className="flex-column ps-4 pe-4">
                                                        How to claim my rewards?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="19">
                                                            <ul>
                                                                <li>Use your phone's camera to scan the QR code attached to the lost item.</li>
                                                                <li>A screen will emerge, offering you 5 options including call, text, email, and more.</li>
                                                                <li>At the bottom of the same page, you'll find the intriguing "Claim Reward" option.</li>
                                                                <li>A box will pop up, prompting you to enter your details along with a description of the item you've returned to its owner to assist them.</li>
                                                                <li>Click the "Submit" button to finalize your claim and act of kindness.</li>
                                                                <li>Your part is complete! Await your reward, along with an appreciation certificate, delivered to your provided email address.</li>
                                                                <li>Claim Your Well-Earned Reward</li>  
                                                            </ul>
            
                                                            <p>Seize the opportunity to claim the recognition and reward you rightfully deserve for your commendable action.</p>
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="20" className="flex-column ps-4 pe-4">
                                                        How can I enable or disable specific services?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="20">
                                                            <ul>
                                                                <li>Access the Intello Tags app with your User ID and Password at <a href="www.intellotags.com">www.intellotags.com</a>.</li>
                                                                <li>Once logged in, find the menu on the left side of the screen.</li>
                                                                <li>Click "Activated Tags" to manage your QR codes.</li>
                                                                <li>Select your desired tag, then click "Edit Tag" for more options.</li>
                                                                <li>Under the "DND" tab, activate or deactivate services as needed.</li>
                                                                <li>Don't forget to save your changes by clicking "Submit."</li>
                                                            </ul>
                                                            <p>This simplified process makes updating and managing your Intello Tags a breeze.</p>
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="21" className="flex-column ps-4 pe-4">
                                                        Is it possible to temporarily disable my tag?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="21">
                                                        We are actively developing the capability to accommodate this request in the near future. Our dedicated team is diligently working on its implementation. Presently, this feature remains unavailable. 
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="22" className="flex-column ps-4 pe-4">
                                                        Where I can get a promocode ?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="22">
                                                        Whenever you purchase Intello Tags from a registered shopkeeper/merchant, they will provide you with a promo code. You can use this code to avail an additional 5% discount on your Intello Tag.
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="23" className="flex-column ps-4 pe-4">
                                                        Is it possible to receive a complimentary sample of the Intello Tag?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="23">
                                                            <p>"Yes, you can receive a complimentary sample of Intello Tags, which comes with a 15-day validity period and limited services. To request a free sample, please follow these steps:</p>
                                                            <ul>
                                                                <li>Visit the Intello Tags website at <a href="www.intellotags.com">www.intellotags.com</a> or download the Intello Tags app from Google Play Store for Android users or the Apple App Store for iOS users.</li>
                                                                <li>Log in to your account.</li>
                                                                <li>Select the free product option.</li>
                                                                <li>Use the "FreeIntellotag" promo code during checkout.</li>
                                                                <li>Click on "Buy."</li>
                                                            </ul>
                                                            <li>Your free sample of Intello Tag will be accessible in the 'My Orders' section. Simply navigate to 'My Orders,' download the free sample, and proceed to activate the tag by following the provided instructions. Once activated, you can apply it to your belongings or vehicle."</li>
                                                        </AccordionBody>
                                                    </AccordionItem>
            
                                                    <AccordionItem className="mb-2">
                                                        <AccordionHeader children="true" targetId="24" className="flex-column ps-4 pe-4">
                                                        Can I take a franchise of Intello Tags?
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId="24">
                                                            <p>Yes, you can become a franchisee of Intello Tags. To initiate the process, please visit our website at <a href="www.intellotags.com">www.intellotags.com</a>. Scrolls down the website, and in the footer, you will find a 'Become a Franchise' link. Simply click on that link, complete the required details in the provided form, and submit it.</p>
                                                            <p>Once we receive your submission, our team will promptly review your request and get in touch with you to discuss further details and the procedure.</p>
                                                        </AccordionBody>
                                                    </AccordionItem>
                                                    
                                                </Accordion>
                                            </div>
                                    </div>
                                      
                                }
                                  </Col>
                                    </Row>
                            </TabPane>
                        </TabContent>
                    </Container>
                </section>
                
            </div>
            </HeaderFooter>
      </React.Fragment>
    );
  }
  export default Career;