import React from 'react';
// import { NavLink } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { setScrollToOnlineStoreDispatch } from '../../Screen/Home/Slices/scrollManagment.slice';
import { setActiveTabNavigarion } from '../../Store/Combined.reducer.js/CommanReducer/tabNavigation.slice';
import { checkIsSecondWebview, checkMobileWebView } from '../../utility/helper';
// import './TabNavigation.css'; // Styling for the tab navigation

const TabNavigation = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();
  const scrollManagment= useSelector(state=>state.scrollManagment);

  if(checkMobileWebView() && checkIsSecondWebview(location.pathname)){
    return <div 
      className="tab-navigation"
      style={{backgroundColor: "#9AE0FD"}}
      onClick={()=>{
        window.ReactNativeWebView.postMessage('formSubmitted');
      }}
    >
      Home
    </div>
  }
  
  if(!checkIsSecondWebview(location.pathname))
    return (
      <div className="tab-navigation" style={{backgroundColor: !checkMobileWebView() ? "#9AE0FD" : "#ffffff"}}>
        <NavLink 
          activeClassName="active"
          onClick={()=>{
            navigate('/profile');
            dispatch(setActiveTabNavigarion("profile")); 
          }} 
          href="#"
        >
          Profile
        </NavLink>

        <NavLink 
          activeClassName="active"
          onClick={()=>{
            navigate('/');
            dispatch(setScrollToOnlineStoreDispatch(scrollManagment.scrollToOnlineStore+1));
          }} 
          href="#"
        >
          Buy
        </NavLink>
        <NavLink
          href="#"
          activeClassName="active"
        >
          Scan
        </NavLink>
        <NavLink 
          // exact
          activeClassName="active"
          onClick={()=>{
            navigate('/profile');
            dispatch(setActiveTabNavigarion("activated-tag"));
          }} 
          href="#"
        >
          My Tags
        </NavLink>
        <NavLink 
          // exact
          activeClassName="active"
          onClick={()=>{
            navigate('/cart');
          }} 
          href="#"
        >
          Cart
        </NavLink>
      </div>
    );
  
  return null
};

export default TabNavigation;
