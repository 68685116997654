import { configureStore } from '@reduxjs/toolkit'
import dummy from './CommanReducer/dummy.reducer'
import ProductsListing from '../../Screen/Home/Slices/ProductsListing'
import ProductCategory from './../../Screen/Home/Slices/ProductCategory.slice'
import scrollManagment from './../../Screen/Home/Slices/scrollManagment.slice'
import Product from './../../Screen/Home/Slices/Product.slice'
import cart from './../../Screen/Home/Slices/cart.slice'
import auth from './../../Screen/Authentications/Login/slice.reducer'
import profile from './../../Screen/Pro/profile.slice'
import LoaderForIndividualPage from './../../Components/Loader/LoaderForIndividualPage.slice.reducer'
import loader from './../../Components/Loader/slice.reducer'
import tabNavigation from './CommanReducer/tabNavigation.slice'

export default configureStore({
    reducer: {
        dummy,
        ProductCategory,
        ProductsListing,
        Product,
        auth,
        cart,
        profile,
        LoaderForIndividualPage,
        loader,
        scrollManagment,
        tabNavigation
    }
})