import React, {useEffect, useRef, useState } from "react";
import {Col, Row, Container,NavLink } from 'reactstrap';


import styled from 'styled-components';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';

import { setScrollToOnlineStoreDispatch } from "../../Screen/Home/Slices/scrollManagment.slice";
import Video from './../../Assets/Videos/final-sarthik-explanatory.mp4';

import { useNavigate } from 'react-router-dom'
import StickySection from '../../Components/animation';
import Bg1 from './../../Assets/Images/poster/BG1.jpg';
import Bg2 from './../../Assets/Images/poster/bg2.jpg';
import Bg3 from './../../Assets/Images/poster/bg3.jpg';
import Bg4 from './../../Assets/Images/poster/1.jpg';
import Sale50 from './../../Assets/Images/poster/offer50.png';
import Sale70 from './../../Assets/Images/poster/offer70.png';
import Sale5 from './../../Assets/Images/poster/5.png';
import Gold from './../../Assets/Images/poster/2222.png';
import Play from './../../Assets/Images/play.png';

import Buy from './../../Assets/Images/buy.png';
import Activate from './../../Assets/Images/activate.png';
import Attach from './../../Assets/Images/attach.png';
import Scan from './../../Assets/Images/scan.png';
import Communicate from './../../Assets/Images/communicate.png';
import Reward from './../../Assets/Images/reward.png';

import sale1Product1 from './../../Assets/Images/poster/car.png';
import sale1Product2 from './../../Assets/Images/poster/watch.png';

import sale2Product1 from './../../Assets/Images/poster/tag.png';
import sale2Product2 from './../../Assets/Images/poster/bag.png';
import sale2Product3 from './../../Assets/Images/poster/dog.png';

import Poster from './../../Assets/Images/Video-2.png';
// import Slider from "react-slick";

import Products from "./Section/OnlineStore/Products";
import ExquisiteProducts from "./Section/ExquisiteProducts";
import IntelloFeatures from "./Section/IntelloFeatures";
import NewsBeyond from "./Section/News&Beyond";
import IntelloCommunity from "./Section/IntelloCommunity";
import LetHumanityBreathe from "./Section/LetHumanityBreathe";
import HeaderFooter from "../../HeaderFooter";
import {useLocation} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { resetProductsList } from "./Slices/ProductsListing";
import { resetProductCategory } from "./Slices/ProductCategory.slice";

const SectionWipes2Styled = styled.div`
  overflow: hidden;
  #pinContainer {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
  #pinContainer .panel {
    height: 100vh;
    width: 100vw;
    position: absolute;
    text-align: center;
    display:flex;
    align-items:center;
    justify-content:center;
  }
  #pinContainer .panel {
    height:100%;
    object-fit:contain;
    overflow:hidden;
  }
  #pinContainer .panel1 {
    background:url(${Bg1}) !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
  }
  #pinContainer .panel2 {
    background:url(${Bg2}) !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
  }

  #pinContainer .panel3 {
    background:url(${Bg3}) !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
  }

  #pinContainer .panel4 {
    background:url(${Bg4}) !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
  }
  #pinContainer .panel4 h1{
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 300;
  }
  #pinContainer .panel4 h3{
    font-weight:400;
  }
  #pinContainer .panel1 h3,#pinContainer .panel2 h2, #pinContainer .panel3 h2,  #pinContainer .panel3 h1 {
    font-family:'Rajdhani', sans-serif;
  }
  
`;

// scan data 
const scanData = [
    {image: Buy, title: 'Buy', subTitle: 'Intello Tags from our website/App' },
    {image: Activate, title: 'Activate', subTitle: 'Intello Tags by using your credentials' },
    {image: Attach, title: 'Attach', subTitle: 'Intello Tags to your vehicles / valuables' },
    {image: Scan, title: 'Scan', subTitle: 'Intello Tags from any scanning app / google lens' },
    {image: Communicate, title: 'Communicate', subTitle: 'With the owner of tag through call, whatsapp and email' },
    {image: Reward, title: 'Reward', subTitle: 'Get appreciation certificate and 5% lifetime off on Intello Tags' }
]
  
export default ()=>{

    const dispatch = useDispatch()
    const { state, pathname } = useLocation();
    const productCategories= useSelector(state=>state.ProductCategory);
    const ProductsListing= useSelector(state=>state.ProductsListing);
    const scrollManagment= useSelector(state=>state.scrollManagment);
    // const [currentPth, setCurrentPth] = useState(pathname.split("/")[1]);

    const navigate = useNavigate()
    const [delayAnimationView, setDelayAnimationView] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(()=>{
        if( scrollManagment.scrollToOnlineStore && scrollManagment.scrollToNews === 0 ){
            
            const section2 = document.getElementById('online-store');
            section2.scrollIntoView({ behavior: 'smooth' }); 
            
        }else if(scrollManagment.scrollToNews !== 0 ){
            // console.log("statestatestate", typeof scrollManagment.scrollToNews, typeof 0, scrollManagment.scrollToNews, 0);
            const section2 = document.getElementById('news');
            section2.scrollIntoView({ behavior: 'smooth' }); 
        }else{
            window.scrollTo(0, 0);
        }
    }, [scrollManagment, productCategories.isResGet,
        ProductsListing.isResGet]);

    useEffect(()=>{
        dispatch(resetProductsList());
        dispatch(resetProductCategory());
    }, []);

    const videoRef = useRef(null);

    const togglePlay = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };
    
    return (
        <React.Fragment>
            <div>
                <HeaderFooter>
                    <main className="main home">
                        {/* {!delayAnimationView &&  */}
                        <StickySection />
                        {/* } */}
                        <section className="scanSection large-container pt-0">
                            <Container>
                                <div className="text-center  section-heading " >
                                    <h3 className="section-title" data-aos="zoom-in-up" data-aos-duration="700" data-aos-delay="0"><span>Scan made life</span> Simpler</h3>
                                    <p data-aos="zoom-in-up" data-aos-delay="50" data-aos-duration="700">A scan is tiny but peace of mind is huge</p>
                                    <div data-aos="zoom-in-up" data-aos-delay="100" data-aos-duration="700"><span class="sm-title"></span></div>
                                </div>
                                <Row className="text-center">
                                    {scanData
                                    .map((item, index) => (
                                        <Col sm={6} md={4} className="p-4 scan-item" data-aos="fade-right" data-aos-duration="700" data-aos-delay={`${index}00`} key={index}>
                                        <img className="mb-4" src={item.image} alt={item.title} />
                                            <h4 className="text-primary">{item.title}</h4>
                                            <p>{item.subTitle}</p>
                                        </Col>
                                    ))}
                                </Row>
                        </Container>
                        </section>

                        <section className={`video-section ${isPlaying ? "Pause" : "Play"}`} >     
                            <div className="container" data-aos="zoom-in-up" data-aos-delay="100" data-aos-duration="1000">
                            <h1>INTELLO TAGS</h1>
                               <div className="vdo-wrap">
                                <video ref={videoRef} loop  width="100%" height="auto" poster={Poster} controls>
                                    <source src={Video} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <button onClick={togglePlay} id="play">
                                    <img src={Play} alt="play" />
                                </button>
                               </div>
                            </div>
                        </section>

                        <section id="online-store" className="pb-4">
                            <Products />
                        </section>

                        <section className="bg-light slickCenterMode">
                            <ExquisiteProducts />
                        </section>

                        <section className="Intelligence">
                            <IntelloFeatures />
                        </section>

                        <section className="bg-light blog-sec" id="news">
                            <NewsBeyond />
                        </section>
                        
                        <section className="bg-semiLight save p-0"  >
                            <SectionWipes2Styled>
                                <Controller>
                                <Scene
                                    classToggle="sale-animation"
                                    triggerHook="onLeave"
                                    duration="300%"
                                    offset={-50}
                                    pin
                                >
                                    <Timeline
                                    wrapper={<div id="pinContainer" />}
                                    >
                                    <div className="panel panel1 bg-primary">
                                        <Container>
                                            <Row className="align-items-center min-w-100">
                                                <Col lg={6} className="text-center px-5"><img src={Sale50} alt="save" className="w-100 mb-3 saveimg" /></Col>
                                                <Col lg={6} className="text-center sale-content">
                                                    <h2 className="text-uppercase"><span>Upgrade your Security</span></h2>
                                                    <h3 className="text-uppercase mt-3 mb-3 mt-md-4 mb-md-4">Get flat 50% off on Vehicle and Gadget tags, Drive Smart, Live Smart!</h3>
                                                    <div className="mb-4 mb-md-5 sale-products">
                                                        <Row className="justify-content-center">
                                                            <Col xl={9}>
                                                                <Row>
                                                                    <Col>
                                                                        <img className="img-fluid px-2 mobile-view" src={sale1Product1} alt="Img1" />
                                                                    </Col>
                                                                    <Col>
                                                                        <img className="img-fluid px-2 mobile-view" src={sale1Product2} alt="Img1" />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <button
                                                    onClick={()=>{
                                                        dispatch(setScrollToOnlineStoreDispatch(scrollManagment.scrollToOnlineStore+1));
                                                    }}
                                                    className="btn btn-primary btn-xl">SHOP NOW</button>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                    <Tween
                                        from={{ x: '-100%' }}
                                        to={{ x: '0%' }}
                                    >
                                    <div className="panel panel2 bg-primary">
                                        <Container>
                                        <Row className="align-items-center min-w-100">
                                            <Col lg={6} className="text-center px-5"><img src={Sale70} alt="save" className="w-100 mb-3 saveimg" /></Col>
                                                
                                            <Col lg={6} className="text-center sale-content">
                                                <h2 className="text-uppercase">Keep your pets, luggage, and special children secure with Intello Tags®.</h2>
                                               
                                                <div className="mt-4 mb-4 mt-lg-5 mb-lg-5 sale-products">
                                                    <Row>
                                                        <Col>
                                                            <img className="img-fluid mobile-view" src={sale2Product1} alt="Img1" />
                                                        </Col>
                                                        <Col>
                                                            <img className="img-fluid mobile-view" src={sale2Product2} alt="Img1" />
                                                        </Col>
                                                        <Col>
                                                            <img className="img-fluid mobile-view" src={sale2Product3} alt="Img1" />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                
                                                <button
                                                onClick={()=>{
                                                    dispatch(setScrollToOnlineStoreDispatch(scrollManagment.scrollToOnlineStore+1));
                                                }}
                                                 className="btn btn-primary btn-xl">SHOP NOW</button>
                                            </Col>
                                                
                                            </Row>
                                        </Container>
                                        </div>
                                    </Tween>
                                    <Tween
                                        from={{ x: '100%' }}
                                        to={{ x: '0%' }}
                                    >
                                        <div className="panel panel3 bg-primary">
                                            <Container>
                                                <Row className="align-items-center min-w-100">
                                                    <Col lg={6} className="text-center text-light sale-content">
                                                        <h1 className="text-uppercase">Welcome to Our Store!</h1>
                                                        <h2 className="text-uppercase mt-3 mb-4 mt-md-4 mb-md-5">Get a special treat of 5% on your first purchase.</h2>
                                                        
                                                        <button 
                                                            className="btn btn-primary btn-xl"
                                                            onClick={()=>{
                                                                dispatch(setScrollToOnlineStoreDispatch(scrollManagment.scrollToOnlineStore+1));
                                                            }}    
                                                        >SHOP NOW</button>
                                                    </Col>
                                                    <Col lg={6} className="text-center"><img src={Sale5} alt="save" className="w-100 mt-3 saveimg" /></Col>
                                                    
                                                </Row>
                                            </Container>
                                        </div>
                                    </Tween>
                                    <Tween
                                        from={{ y: '-100%' }}
                                        to={{ y: '0%' }}
                                    >
                                        <div className="panel panel4 bg-primary">
                                            <Container>
                                                <Row className="align-items-center min-w-100">
                                                    <Col lg={6} className="text-start text-light sale-content">
                                                        <h1 className="text-uppercase text-xxl">Turn Your Dreams into Gold</h1>
                                                        <div className="className=mt-3 mb-4 mt-md-4 mb-md-5">
                                                            <h3 className="mb-3">Be the Guardian of profits with Intello Tags Franchise.</h3>
                                                            <h3>Where Security Meets Prosperity.</h3>
                                                        </div>
                                                        
                                                        <button 
                                                        onClick={()=>{
                                                            navigate('/become-a-franchise');
                                                        }}
                                                        className="btn btn-primary btn-xl">Enquire Now</button>
                                                    </Col>
                                                    <Col lg={6} className="text-center"><img src={Gold} alt="save" className="w-100 mt-3 saveimg" /></Col>
                                                    
                                                </Row>
                                            </Container>
                                        </div>
                                    </Tween>
                                    </Timeline>
                                </Scene>
                                </Controller>
                            </SectionWipes2Styled>
                            
                        </section>

                        <section className="huminity">
                            <LetHumanityBreathe />
                        </section>

                        <section className="bg-light community">
                            <IntelloCommunity />
                        </section>
                    </main>
                </HeaderFooter>
            </div>
        </React.Fragment>
    )
}