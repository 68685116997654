import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
    activeTag: "profile"
}

export const tabNavigation = createSlice({
  name: 'tabNavigation',
  initialState: {
   ...initialState
  },
  reducers: {
   
    setActiveTabNavigarion: (state, action)=>{
      return {
        ...initialState,
        activeTag: action.payload
      }
    },

    resetActiveTabNavigarion: (state, action)=>{
      return {...initialState}
  }
  }
})

// Action creators are generated for each case reducer function
export const { setActiveTabNavigarion, resetActiveTabNavigarion } = tabNavigation.actions;

export default tabNavigation.reducer