import { APPLY_PROMO_CODE, BASE_URL, CHANGE_PASSWORD, CHECK_AVAILIABLE_STOCK, CHECK_EMAIL, CHECK_OREDER_CONFIRMAITON, CHECK_OREDER_CONFIRMAITON_QRA, DELETE_ADDRESS, DOCUMENT_FOR_MERCHANT, EMAIL_BASE, FORGOT_PASSWORD, GET_ADDRESS, GET_CITY_FROM_PINCODE, GET_OTP, GET_QR_CODE, ORDER_SUMMARY, POST_ADD_PRODUCT_TO_CART, REGISTRATION, RESEND_EMAIN_VERIFY_USER, RESET_PASSWORD, SAVE_ADDRESS, SEND_EMAIL, SEND_EMAIL_SUPPORT_BF, UPDATE_USER_PROFILE, VERIFY_EMAIL, VERIFY_OTP } from "./apiRoutes";
import { getData, postData, putData } from "./rootService";

// 61 api * 700 /  2500

// AUTHENTICATION SERVICES

export const registrationPost =async (data, dispatch) =>{

    console.log("authServiceauthService ", data)
  
      try{
        
        let url= BASE_URL+REGISTRATION;
        let dataObje= {...data}; 
        let headerConfig = {
          "Content-Type": "application/json"
        }
        const RegistrationRes =await postData({url, headerConfig, dataObje}, dispatch)
        
        if(RegistrationRes.Status === "Fail"){
          throw (RegistrationRes)
        }
        
        return RegistrationRes;
      
    }catch (err){
  
      throw (err)
    }
  
}

  export const resendEmailOnConfirmUser =async (data, dispatch) =>{
  
      try{
        
        let url= BASE_URL+RESEND_EMAIN_VERIFY_USER;
        let dataObje= {...data}; 
        let headerConfig = {
          "Content-Type": "application/json"
        }
        const RegistrationRes =await postData({url, headerConfig, dataObje}, dispatch)
        
        return RegistrationRes;
      
    }catch (err){
  
      throw (err)
    }
  
  }

  // VERIFY EMAIL
  export const verifyEmail =async (data, dispatch) =>{
    try{
      // let url= API.BASE_URL+API.VERIFY_EMAIL; 
      let url= " https://admin.intellotags.com/"+VERIFY_EMAIL+`email=${data.email}`; 
  
      let headerConfig = {
        "Content-Type": "application/json"
      }
      // const verifyEmaildRes =await getData({url, headerConfig}, dispatch);
      const verifyEmaildRes =await getData({url}, dispatch);
      console.log("this is a verifyEmaildRes try",verifyEmaildRes);
      return verifyEmaildRes;
      
    }catch (err){
        console.log("this is a verifyEmaildRes catch",err)
        throw (err)
    }

  }


  export const forgetPassword =async (data, dispatch) =>{
  
      try{
        
        let url= BASE_URL+FORGOT_PASSWORD+`email=${data.email}`;
        let headerConfig = {
          "Content-Type": "application/json"
        }
        const forgetPasswordRes =await postData({url, headerConfig}, dispatch)
        
        return forgetPasswordRes;
      
    }catch (err){
  
      throw (err)
    }
  
  }
  
  export const resetPassword =async (data, resetPassword) =>{
  
      try{
        
        let url= BASE_URL+RESET_PASSWORD;
        let dataObje= {...data}; 
        let headerConfig = {
          "Content-Type": "application/json"
        }
        const resetPasswordRes =await postData({url, headerConfig, dataObje}, resetPassword)
        
        return resetPasswordRes;
      
    }catch (err){
  
      throw (err)
    }
  
  }

  export const postAddToCart =async (data, dispatch) =>{

      try{
        
        let url= BASE_URL+POST_ADD_PRODUCT_TO_CART;
        let dataObje= {...data}; 
        let headerConfig = {
          "Content-Type": "application/json"
        }
        const resPostAddToCart =await postData({url, headerConfig, dataObje}, dispatch)
        return resPostAddToCart;
      
    }catch (err){
  
      throw (err)
    }
  
  }

  export const checkAvailibilityOfProduct =async (data, dispatch) =>{
  
      try{
        console.log("authServiceauthService ", data)
        
        // let url= BASE_URL+REGISTRATION;
        let url= BASE_URL+CHECK_AVAILIABLE_STOCK+`productId=${data.ProductID}&categoryId=${data.CategoryID}&quantity=${data.QTY}&color=${data.Color.slice(1)}`;
        let headerConfig = {
          "Content-Type": "application/json"
        }

        const resPostAddToCart =await getData({url, headerConfig}, dispatch);

        return resPostAddToCart;
      
    }catch (err){
  
      throw (err)
    }
  
  }
  
  // GET CITY FORM PIN-CODE

  export const fetchCityFromPinCode =async (data, dispatch) =>{
  
    try{
      
      // let url= BASE_URL+REGISTRATION;
      
      let url= GET_CITY_FROM_PINCODE+`&codes=${data.pincode}`;
      
      const getCity =await getData({url}, dispatch);
      
      return getCity;
    
  }catch (err){

    throw (err)
  }

}

export const fetchOTP =async (data, dispatch) =>{

  try{
    
    let url= BASE_URL+GET_OTP;
    let dataObje= {...data}; 
    
    let headerConfig = {
      "Content-Type": "application/json"
    }
    const resfetchOTP =await postData({url, headerConfig, dataObje}, dispatch)
    return resfetchOTP;
  
  }catch (err){

    throw (err)
  }

}

export const placeOrder =async (data, dispatch) =>{

  try{
    
    let url= BASE_URL+VERIFY_OTP;
    let dataObje= {...data}; 
    let headerConfig = {
      "Content-Type": "application/json"
    }
    const resplaceOrder =await postData({url, headerConfig, dataObje}, dispatch)
    return resplaceOrder;
  
  }catch (err){

    throw (err)
  }

}

export const checkPromoCode =async (data, dispatch) =>{

  try{
    
    let url= BASE_URL+APPLY_PROMO_CODE;
    let dataObje= {...data}; 
    let headerConfig = {
      "Content-Type": "application/json"
    }
    const rescheckPromoCode =await postData({url, headerConfig, dataObje}, dispatch)
    return rescheckPromoCode;
  
  }catch (err){

    throw (err)
  }

}

export const updateUserProfile =async (data, dispatch) =>{

  try{
    
    let url= BASE_URL+UPDATE_USER_PROFILE;
    let dataObje= {...data}; 
    let headerConfig = {
      "Content-Type": "application/json"
    }
    const resUpdateUserProfile =await postData({url, headerConfig, dataObje}, dispatch)
    return resUpdateUserProfile;
  
  }catch (err){

    throw (err)
  }

}

export const changePassword =async (data, dispatch) =>{

  try{
    
    let url= BASE_URL+CHANGE_PASSWORD;
    let dataObje= {...data}; 
    let headerConfig = {
      "Content-Type": "application/json"
    }
    const reschangePassword =await postData({url, headerConfig, dataObje}, dispatch)
    return reschangePassword;
  
  }catch (err){

    throw (err)
  }

}

export const getOrderSummary =async (data, dispatch) =>{
  
  try{
    
    let url= BASE_URL+ORDER_SUMMARY+`UserID=${data.UserID}&View=${data.View}`;
    let headerConfig = {
      "Content-Type": "application/json"
    }

    const resgetOrderSummary=await getData({url, headerConfig}, dispatch);

    return resgetOrderSummary;
  
  }catch (err){

    throw (err)
  }

}

export const getDeliveryAddress	 =async (data) =>{
  
  try{
    
    let url= BASE_URL+ORDER_SUMMARY+`UserID=${data.UserID}&View=${data.View}`;
    let headerConfig = {
      "Content-Type": "application/json"
    }

    const resgetDeliveryAddress =await getData({url, headerConfig});

    return resgetDeliveryAddress;
  
  }catch (err){

    throw (err)
  }

}

// GET ADDRESS
export const getAddress =async (data, dispatch) =>{
  
  try{
    
     
    let url= BASE_URL+GET_ADDRESS+`userID=${data.UserID}`;
    let headerConfig = {
      "Content-Type": "application/json"
    }

    const res =await getData({url, headerConfig}, dispatch);
    return res;
  
  }catch (err){

    throw (err)
  }

}

// SAVE ADDRESS

export const postsaveAddres =async (data, dispatch) =>{

    try{
      
      let url= BASE_URL+SAVE_ADDRESS;
      let dataObje= {...data}; 
      let headerConfig = {
        "Content-Type": "application/json"
      }
      const res =await postData({url, headerConfig, dataObje}, dispatch)
      
      return res;
    
  }catch (err){

    throw (err)
  }

}

// DELETE ADDRESS

export const postDeleteAddres =async (data, dispatch) =>{

    try{
      
      let url= BASE_URL+DELETE_ADDRESS;
      let dataObje= {...data}; 
      let headerConfig = {
        "Content-Type": "application/json"
      }
      const res =await postData({url, headerConfig, dataObje}, dispatch)
      
      return res;
    
  }catch (err){

    throw (err)
  }

}

  // CHECK ORDER CONFIRMATIOIN
  export const checkOrderConfirmation =async (data, dispatch) =>{
    try{
      let url= " https://admin.intellotags.com/"+CHECK_OREDER_CONFIRMAITON+`UserID=${data.UserID}&InvoiceNumber=${data.InvoiceNumber}`; 
      console.log('this is try checkOrderConfirmation', data)
      let headerConfig = {
        "Content-Type": "application/json"
      }
      const res =await getData({url, headerConfig}, dispatch);
      console.log("this is a res try",res);
      return res;
      
    }catch (err){
        console.log("this is a res catch",err)
        throw (err)
    }
  }

  // CHECK ORDER CONFIRMATIOIN QRA
  export const checkOrderConfirmationQra =async (data, dispatch) =>{
    try{
      let url= " https://admin.intellotags.com/"+CHECK_OREDER_CONFIRMAITON_QRA+`UserID=${data.UserID}&InvoiceNumber=${data.InvoiceNumber}`; 
      console.log('this is try checkOrderConfirmation', data)
      let headerConfig = {
        "Content-Type": "application/json"
      }
      const res =await getData({url, headerConfig}, dispatch);
      console.log("this is a res try",res);
      return res;
      
    }catch (err){
        console.log("this is a res catch",err)
        throw (err)
    }
  }

  // CHECK ORDER CONFIRMATIOIN
  export const getQRcode =async (data, dispatch) =>{
    try{
      let url= BASE_URL+GET_QR_CODE+`CartID=${data.orderID}`; 
  
      let headerConfig = {
        "Content-Type": "application/json"
      }

      const res =await getData({url, headerConfig}, dispatch);
      console.log("this is a res try",res);
      return res;
      
    }catch (err){
        console.log("this is a res catch",err)
        throw (err)
    }
  }

// SEND EMAIL
//  https://admin.intellotags.com//api/user/-1/Sarthii/SendCommonEmail

export const sendEmail =async (data, dispatch) =>{

    try{
      
      let url= BASE_URL+SEND_EMAIL;
      let dataObje= {...data}; 

      let headerConfig = {
        "Content-Type": "application/json"
      }
      const res =await postData({url, headerConfig, dataObje}, dispatch)
      
      if(res.Status === "Fail"){
        throw (res)
      }
      
      return res;
    
  }catch (err){

    throw (err)
  }

}

// bussiness asociation servicess

export const checkEmail =async (data, dispatch) =>{
  try{
    let url= BASE_URL+ CHECK_EMAIL+`email=${data.email}&employeeCode=${data.employeeCode}`;
    // let url= " https://admin.intellotags.com/"+VERIFY_EMAIL+`email=${data.email}`; 

    const res =await getData({url}, dispatch);
    console.log("this is a res try",res);
    return res;
    
  }catch (err){
      console.log("this is a res catch",err)
      throw (err)
  }

}

export const postDocumentForMerchant =async (data, dispatch) =>{

    try{
      
      let url= BASE_URL+DOCUMENT_FOR_MERCHANT;
      let dataObje= data; 

      console.log("this is tryff registration", dataObje);
      let headerConfig = {
        "Content-Type": "application/json"
      }
      const res =await postData({url, headerConfig, dataObje}, dispatch)
      
      if(res.Status === "Fail"){
        throw (res)
      }
      
      return res;
    
  }catch (err){

    throw (err)
  }

}

// EDIT / ACTIVE TAG

export const checkQrCodeDetails =async (data, dispatch) =>{

  try{
      
    let url= BASE_URL+"api/user/-1/GetQRDetailsByQRCode?"+`latitude=-1&longitude=-1&qrCode=${data.qrCode}`;
    // let url= BASE_URL+"api/user/-1/GetQRDetailsByQRCode?"+`qrCode=${data.qrCode}`;
    let dataObje= {...data}; 

    let headerConfig = {
      "Content-Type": "application/json"
    }
    const res =await getData({url, headerConfig}, dispatch)
    
    if(res.Status === "Fail"){
      throw (res)
    }
    
    return res;
  
}catch (err){

  throw (err)
}

}

export const activeTag =async (data, dispatch) =>{

  try{
      
    let url= BASE_URL+"api/user/-1/AddUserDetailsPerQR";
    let dataObje= {...data}; 

    let headerConfig = {
      "Content-Type": "application/json"
    }
    const res =await postData({url, headerConfig, dataObje}, dispatch)
    
    if(res.Status === "Fail"){
      throw (res)
    }
    
    return res;
  
}catch (err){

  throw (err)
}

}

export const postDocument =async (data, dispatch) =>{

  try{
      
      let url= BASE_URL+"api/user/-1/Document";
      let dataObje= data; 

      let headerConfig = {
        "Content-Type": "application/json"
      }
      const res =await postData({url, headerConfig, dataObje}, dispatch)
      
      if(res.Status === "Fail"){
        throw (res)
      }
      
      return res;
    
  }catch (err){

    throw (err)
  }

}

export const putDocument =async (data, dispatch) =>{

  try{
      
      let url= BASE_URL+"api/user/-1/Document/"+data.ID;
      let dataObje= data; 

      let headerConfig = {
        "Content-Type": "application/json"
      }
      const res =await putData({url, headerConfig, dataObje}, dispatch)
      
      if(res.Status === "Fail"){
        throw (res)
      }
      
      return res;
    
  }catch (err){

    throw (err)
  }

}

export const sendEmailToOwner =async (data, dispatch) =>{

  try{
      
    let url= BASE_URL+"api/user/-1/Sarthii//SendEmailFromQRPage";
    let dataObje= {...data}; 

    let headerConfig = {
      "Content-Type": "application/json"
    }
    const res =await postData({url, headerConfig, dataObje}, dispatch)
    
    if(res.Status === "Fail"){
      throw (res)
    }
    
    return res;
  
}catch (err){

  throw (err)
}

}

export const claimTheReward =async (data, dispatch) =>{

  try{
      
    let url= BASE_URL+"api/user/claimreward";
    let dataObje= {...data}; 

    let headerConfig = {
      "Content-Type": "application/json"
    }
    const res =await postData({url, headerConfig, dataObje}, dispatch)
    
    if(res.Status === "Fail"){
      throw (res)
    }
    
    return res;
  
}catch (err){

  throw (err)
}

}

export const isUserAvailiable =async (data, dispatch) =>{

  try{
      
    let url= BASE_URL+"api/user/-1/Sarthii/GetUserDetailByEmail?"+`email=${data.email}`;

    let headerConfig = {
      "Content-Type": "application/json"
    }
    const res =await getData({url, headerConfig}, dispatch)
    
    if(res.Status === "Fail"){
      throw (res)
    }
    
    return res;
  
}catch (err){

  throw (err)
}

}



// new


// TO CHECK IS LINK EXPIRED ??
export const fetchCheckLinkExpiry =async (data, dispatch) =>{

  try{
      
    let url= BASE_URL+"api/user/-1/CheckLinkExpiry?qrCode=M71945";

    let headerConfig = {
      "Content-Type": "application/json"
    }
    const res =await getData({url, headerConfig}, dispatch)
    
    if(res.Status === "Fail"){
      throw (res)
    }
    
    return res;
  
}catch (err){

  throw (err)
}

}


// TO GET ACTIVE TAG'S DETAILS
export const fetchActivateTagList =async (data, dispatch) =>{

  try{

    let url= BASE_URL+`api/user/${data.userId}/Sarthii/GetActivateTags`;

    let headerConfig = {
      "Content-Type": "application/json"
    }
    const res =await getData({url, headerConfig}, dispatch)
    
    if(res.Status === "Fail"){
      throw (res)
    }
    
    return res;
  
}catch (err){

  throw (err)
}

}

export const fetchActivateTagDetails =async (data, dispatch) =>{

  try{
      
    let url= BASE_URL+"api/user/-1/GetUserDetailsByQRCodeForActivateTag?"+`qrCode=${data.qrCode}`;

    let headerConfig = {
      "Content-Type": "application/json"
    }
    const res =await getData({url, headerConfig}, dispatch)
    
    if(res.Status === "Fail"){
      throw (res)
    }
    
    return res;
  
}catch (err){

  throw (err)
}

}

// TO GET DOCUMENTS
export const fetchDocuments =async (data, dispatch) =>{

  try{
      
    let url= BASE_URL+"api/user/-1/Sarthii/GetDocuments?"+`qrCode=${data.qrCode}`

    let headerConfig = {
      "Content-Type": "application/json"
    }
    const res =await getData({url, headerConfig}, dispatch)
    
    if(res.Status === "Fail"){
      throw (res)
    }
    
    return res;
  
}catch (err){

  throw (err)
}

}

export const postSaveLastScan =async (data, dispatch) =>{

  try{
    
    let url= BASE_URL+`api/user/-1/Sarthii/SaveLastScan`;
    let dataObje= {...data}; 

    let headerConfig = {
      "Content-Type": "application/json"
    }
    const res =await postData({url, headerConfig, dataObje}, dispatch)
    
    if(res.Status === "Fail"){
      throw (res)
    }
    
    return res;
  
}catch (err){

  throw (err)
}

}

// TO GET LAST LOCATION
export const fetchLastScanLocations =async (data, dispatch) =>{

  try{
      
    let url= BASE_URL+`api/user/-1/Sarthii/GetLastScan?qrCode=${data.qrCode}`;

    let headerConfig = {
      "Content-Type": "application/json"
    }
    const res =await getData({url, headerConfig}, dispatch)
    
    if(res.Status === "Fail"){
      throw (res)
    }
    
    return res;
  
}catch (err){

  throw (err)
}

}

// TO SAVE DND
export const postSaveDND =async (data, dispatch) =>{

  try{
      
    let url= BASE_URL+"api/user/-1/SaveDNDValue/";
    let dataObje= {...data}; 

    let headerConfig = {
      "Content-Type": "application/json"
    }
    const res =await postData({url, headerConfig, dataObje}, dispatch)
    
    if(res.Status === "Fail"){
      throw (res)
    }
    
    return res;
  
}catch (err){

  throw (err)
}

}

// TO SAVE DND
export const enableDisableTag =async (data, dispatch) =>{

  try{
      
    let url= BASE_URL+"api/user/-1/DNDForAll/";
    let dataObje= {...data}; 

    let headerConfig = {
      "Content-Type": "application/json"
    }
    const res =await postData({url, headerConfig, dataObje}, dispatch)
    
    if(res.Status === "Fail"){
      throw (res)
    }
    
    return res;
  
}catch (err){

  throw (err)
}

}

// TO UPDATE USER DETAILS PER QR
export const postUpdateUserDetailsPerQR =async (data, dispatch) =>{

  try{
      
    let url= BASE_URL+"api/user/-1/UpdateUserDetailsPerQR/";
    let dataObje= {...data}; 

    let headerConfig = {
      "Content-Type": "application/json"
    }
    const res =await postData({url, headerConfig, dataObje}, dispatch)
    
    if(res.Status === "Fail"){
      throw (res)
    }
    
    return res;
  
}catch (err){

  throw (err)
}

}

// TO SET LINK EXPIRED
export const toSetLinkExpired =async (data, dispatch) =>{

  try{
      
    let url= BASE_URL+"api/user/-1/SetLinkExpired?qrCode=M71945";

    let headerConfig = {
      "Content-Type": "application/json"
    }
    const res =await getData({url, headerConfig}, dispatch)
    
    if(res.Status === "Fail"){
      throw (res)
    }
    
    return res;
  
}catch (err){

  throw (err)
}

}

export const sendCommanEmail =async (data, dispatch) =>{

  try{
    
    let url= EMAIL_BASE +SEND_EMAIL_SUPPORT_BF;
    let dataObje= {...data}; 

    let headerConfig = {
      "Content-Type": "application/json"
    }
    const res =await postData({url, headerConfig, dataObje}, dispatch)
    
    if(res.Status === "Fail"){
      throw (res)
    }
    
    return res;
  
}catch (err){

  throw (err)
}

}

