// export const BASE_URL = " https://admin.intellotags.com//";
export const BASE_URL = "https://admin.intellotags.com/";
export const EMAIL_BASE = "https://admin.intellotags.com/";

// PRODUCT
export const PRODUCT_CATEGORIES = "api/user/-1/Sarthii/GetCategory";
export const PRODUCTS_LIST = "api/user/-1/Sarthii/GetProductListing?";
export const PRODUCTS = "api/user/-1/Sarthii/GetProductListing?";

// Authentication
export const LOG_IN = "api/user/-1/Sarthii/Login";
export const REGISTRATION = "api/user/-1/Sarthii/SignUp";
export const RESEND_EMAIN_VERIFY_USER = "api/user/-1/Sarthii/resend_email_verification";
export const VERIFY_EMAIL = "api/user/-1/EmailVerification?";
export const FORGOT_PASSWORD = "api/user/-1/ResetPassword?";
export const RESET_PASSWORD = "api/user/-1/ResetPassword?";

// CART
export const CHECK_AVAILIABLE_STOCK = "api/user/-1/CheckAvailableStock?";
export const GET_CART_PRODUCT = "api/user/-1/Sarthii/GetCartListing?";
export const POST_ADD_PRODUCT_TO_CART = "api/user/-1/Sarthii/AddCart";
export const REMOVE_PRODUCT_FROM_CART = "api/user/-1/Sarthii//RemoveCart";
export const APPLY_PROMO_CODE = "api/user/-1/Sarthii/CheckPromoCode";

// PROFILE
export const GET_USER_PROFILE_DETAILS = "api/user/-1/Sarthii/GetUserDetails?";
export const UPDATE_USER_PROFILE = "api/user/-1/UserProfileDetail";
export const CHANGE_PASSWORD = "api/user/-1/ProfileChangePassword";
export const GET_ADDRESS = "api/user/-1/Sarthii/GetUserAddress?";
export const SAVE_ADDRESS = "api/user/-1/Sarthii/SaveUserAddress";
export const DELETE_ADDRESS = "api/user/-1/Sarthii/DeleteUserAddress";

// OTHERS
// export const GET_CITY_FROM_PINCODE = "https://staging-express.delhivery.com/c/api/pin-codes/json/?";
export const GET_CITY_FROM_PINCODE = "https://app.zipcodebase.com/api/v1/search?apikey=bafe3060-7247-11ee-8076-1d4157d5dac7";
export const GET_OTP = "api/user/-1/Sarthii//SendOTP"; // <-- to get mobile otp
export const VERIFY_OTP = "api/user/-1/Sarthii//verifyOTP"; // <-- to verify mobile otp

// ORDER
export const ORDER_SUMMARY = "api/user/-1/Sarthii/GetCartListing?"; // <-- to verify mobile otp
export const CHECK_OREDER_CONFIRMAITON = "api/user/-1/Sarthii/GetTransactionDetailsByInvoiceNumber?"; // <-- to verify mobile otp
export const CHECK_OREDER_CONFIRMAITON_QRA = "api/user/-1/Sarthii/GetQRActiveOrNotByInvoiceNumber?"; // <-- to verify mobile otp
export const SEND_EMAIL = "api/user/-1/Sarthii/SendCommonEmail";
export const SEND_EMAIL_WITH_ATTACTMENT = "api/user/-1/Sarthii/SendCareerEmailWithAttachment";

// QR
export const GET_QR_CODE = "api/user/-1/Sarthii/GetQRByCartID?";

// BUSINESS ASSOSIATION API
export const CHECK_EMAIL = "api/user/-1/Sarthii/CheckEmailMerchant?";
export const DOCUMENT_FOR_MERCHANT = "api/user/-1/DocumentForMerchant";

// EMAIL
export const SEND_EMAIL_SUPPORT_BF = "/api/user/-1/Sarthii/SendCommonEmail";
