import React, {useEffect, useState} from "react";

import { NavLink, useNavigate} from "react-router-dom";
import { Row, Col } from 'reactstrap'
import Eye from './../../../Assets/Images/eye.png';
import Logo from './../../../Assets/Images/logo.png';
import EyeOff from './../../../Assets/Images/eye-off.png';
import validator from 'validator';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { registrationPost } from "../../../utility/service";
import LoginSlider from "../../../Components/LoginSlider";
import { checkMobileWebView } from "../../../utility/helper";

function Registration() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordMaskedOPen, setIsPasswordMaskedOPen] = useState(true)
  const [isConPasswordMaskedOPen, setConIsPasswordMaskedOPen] = useState(true)
  const [isChecked, setIsChecked] = useState(false)

  const [firstNameErr, setrFirstNameErr] = useState(false)
  const [lastNameErr, setLastNameErr] = useState(false)
  const [emailNameErr, setEmailNameErr] = useState(false)
  const [passwordNameErr, setPasswordNameErr] = useState(false)
  const [consfirmfirstNameErr, setConsfirmfirstNameErr] = useState(false)

  useEffect(()=>{
      window.scrollTo(0, 0);
  }, [])
  
  const registration = async (e)=>{
   
    
    e.preventDefault();
    toast.dismiss()
   
    if(validator.isEmpty(firstName.trim())){
      toast.error("Please Enter First Name", { });
      setrFirstNameErr(true)
      return;
    }else if(!validator.isLength(firstName.trim(), {min: 1, max: 25})){
      toast.error("First Name length should be minimum 3 char and maximum 25 char", { });
      setrFirstNameErr(true)
      return;
    }
   
    if(validator.isEmpty(lastName.trim())){
      toast.error("Please Enter Last Name", { });
      setLastNameErr(true)
      return;
    }else if(!validator.isLength(lastName.trim(), {min: 1, max: 25})){
      toast.error("Last Name length should be minimum 3 char and maximum 25 char", { });
      setLastNameErr(true)
      return;
    }

    if(!validator.isEmail(email, {blacklisted_chars : "+-"})){
      if(email === "") toast.error("Please Enter Email", { });
      else toast.error("Please Enter valid Email", { });
      setEmailNameErr(true)
      return
    }
    
    if(!validator.isLength(password, {min: 8, max: 14})){
      if(password === "") {
        toast.error("Please Enter Password", { });
        setPasswordNameErr(true)
      }else {
        toast.error("Password length should be minimum 8 char and maximum 14 char", { });
        setPasswordNameErr(true)
      }
      return;
    }


    if(validator.isEmpty(confirmPassword)){
      toast.error("Please Enter Confirm Password", { });
      setConsfirmfirstNameErr(true)
      return;
    }else if(password !== confirmPassword){
      toast.error("Password and Confirm Password should be same", { });
      setConsfirmfirstNameErr(true)
      return;
    }

    if(isChecked === false){
      toast.error("Please accept Terms and Conditions", { });
      return;
    }

    let registrationDATA = {
      Name: firstName.trim(),
      LastName: lastName.trim(),
      Email :email,
      Password: password,
    } 

    try{
      console.log('this is try registration', "try")
      await registrationPost(registrationDATA, dispatch); 
      toast.dismiss();
      toast("Registration Successfully, You can Login here", { });
      navigate('/login', { state: registrationDATA})
      // navigate('/email-varification', { state: registrationDATA})
    }catch (err){
      if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
      
    }
  }

  const onInputChange = (e)=>{
    
    switch(e.target.name){
      case "firstName":
        setFirstName(e.target.value.replace(/[^a-z\s]/gi, ''));
        setrFirstNameErr(false)
        break;
      case "lastName":
        setLastName(e.target.value.replace(/[^a-z]/gi, ''));
        setLastNameErr(false)
        break;
      case "email":
        setEmail(e.target.value);
        setEmailNameErr(false)
        break;
      case "password":
        setPassword(e.target.value);
        setPasswordNameErr(false)
        break;
      case "confirmPassword":
        setConfirmPassword(e.target.value);
        setConsfirmfirstNameErr(false)
        break;
    }
  }
  
    return (
      <React.Fragment>
        <div className="container-fluid p-0 signup-page">
        <Row className="m-0">
            <Col className="p-0" lg={6}>
              <LoginSlider />
            </Col>
            <Col className="pt-3 signupScreen" lg={6} data-aos="zoom-in-up" data-aos-delay="200" data-aos-duration="200">
            
                <div className="signup-wrap">
                  {/* Logo  */}
                  <div className="text-center mb-3 mt-3">
                                                <a 
                                href= {checkMobileWebView()? "#" :"/"}
                                onClick={(e)=>{
                                    if(checkMobileWebView()){
                                        e.preventDefault();
                                        window.ReactNativeWebView.postMessage('formSubmitted');
                                    }
                                }}
                            ><img className="mb-4 pb-1" src={Logo} alt="" /></a>
                    <h4>Sign up</h4>
                  </div>

                  {/* signup form start  */}
                    <form className="pb-5 mb-2 mb-md-0 pt-1" onSubmit={registration}>
                      <div className="mb-3">
                          <input 
                            value={firstName} 
                            onChange={onInputChange} 
                            type="text" 
                            // className={'form-control'} 
                            name="firstName" 
                            placeholder="First Name" 
                            className={classNames('form-control', { "form-error": firstNameErr })}
                          />
                      </div>
                      <div className="mb-3">
                          <input 
                            value={lastName} 
                            onChange={onInputChange}  
                            type="text" 
                            // className={'form-control'} 
                            name="lastName" 
                            placeholder="Last Name" 
                            className={classNames('form-control', { "form-error": lastNameErr })}
                          />
                      </div>
                      <div className="mb-3">
                          <input 
                            value={email} 
                            onChange={onInputChange}  
                            type="text" 
                            // className={'form-control'} 
                            name="email" 
                            placeholder="Email"
                            className={classNames('form-control', { "form-error": emailNameErr })} 
                          />
                      </div>
                      {/* <div className="mb-3">
                          <input ref={phoneNumber} type="text" className={classNames('form-control', { "form-error": true })} name="phoneNumber" placeholder="Phone Number" />
                      </div> */}
                      <div className="mb-3 password-field">
                          <input 
                            value={password} 
                            onChange={onInputChange}  
                            type={isPasswordMaskedOPen ?'password': "Text"} 
                            name="password" 
                            // className={'form-control'} 
                            placeholder="Password" 
                            className={classNames('form-control', { "form-error": passwordNameErr })}
                          />
                          <span onClick={()=>setIsPasswordMaskedOPen(!isPasswordMaskedOPen)}>
                              <img src={isPasswordMaskedOPen ?Eye: EyeOff} alt="Eye" />
                              {/* <img src={EyeOff} className="d-none" alt="Eye" /> */}
                          </span>
                      </div>
                      <div className="mb-3 password-field">
                          <input 
                            value={confirmPassword} 
                            onChange={onInputChange}  
                            type={isConPasswordMaskedOPen ?'password': "Text"} 
                            name="confirmPassword" 
                            // className={'form-control'} 
                            placeholder="Confirm Password" 
                            className={classNames('form-control', { "form-error": consfirmfirstNameErr })}
                          />
                          <span onClick={()=>setConIsPasswordMaskedOPen(!isConPasswordMaskedOPen)}>
                              <img src={isConPasswordMaskedOPen ?Eye: EyeOff} alt="Eye" />
                              {/* <img src={EyeOff} className="d-none" alt="Eye" /> */}
                          </span>
                      </div>
                      <div className="form-check">
                        <input 
                          className="form-check-input" 
                          type="checkbox" 
                          id="check" 
                          checked={isChecked}
                          onChange={()=>setIsChecked(!isChecked)}
                        />
                        <label className="form-check-label" htmlFor="check">
                        I accept <NavLink 
                           to="https://www.intellotags.com/#/privacy-policy" 
                           target="_blank"><u>privacy policy </u></NavLink> and {" "}
                       <NavLink 
                            to="https://www.intellotags.com/#/terms-of-use" 
                           target="_blank"><u>terms and use </u></NavLink>
                        </label>
                      </div>
                    
                      <div className="mb-4 mt-4">
                          <button className="btn btn-primary w-100">Sign up</button>
                      </div>
                      <div className="text-center pt-2">
                        Already have an account? <NavLink to="/login"><u>Login</u></NavLink>
                      </div>
                    </form>
                  {/* signup form end  */}
                </div>

                {/* copyright content  */}
                <div class="copyright text-center p-3"><p class="m-0">Copyright 2024. © Intello Tags All Rights Reserved</p></div>
              </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
  
  export default Registration;