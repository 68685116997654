import React from "react";
import {Container, Row, Col } from 'reactstrap';
import Logo from './../../../../Assets/Images/logo.png';
import { useLocation, useNavigate } from "react-router-dom";
import { checkMobileWebView } from "../../../../utility/helper";

function Cart() {
    
    const {state} = useLocation();
    const navigate = useNavigate();
    console.log("statestatestate me", state)
    
    return (
    <React.Fragment>
        <main className="bg-light qr-screen h-100vh">
            <header className="header  px-3 px-sm-4">
                <div className="container">
                    <nav class="fixed-top1 navbar-expand-lg navbar navbar-light bg-faded">
                                                    <a 
                                href= {checkMobileWebView()? "#" :"/"}
                                onClick={(e)=>{
                                    if(checkMobileWebView()){
                                        e.preventDefault();
                                        window.ReactNativeWebView.postMessage('formSubmitted');
                                    }
                                }}
                            >
                            <img src={Logo} alt="Logo" className="img-fluid mt-0" />
                        </a>
                    </nav>
                </div>
            </header>
            <section>
                <Container>
                    <Row className="justify-content-center text-center">
                        <Col xl={6}>
                            <div className="mb-2 ">
                                <h6 className="light-title text-uppercase text-primary">Welcome to</h6>
                                <h3 class="text-uppercase text-primary">Intello Tags</h3>
                                <p className="mw-250 font-medium mb-0">Medical history of Tag's owner</p>
                            </div>
                            <div className="qr-from text-start p-3 p-sm-4">
                                <div className="white-box">
                                    <h6 className="text-center mb-0 pt-2">Medical History</h6>
                                    <form className="p-3" >
                                    
                                        <Row className="justify-content-center">
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <label className="form-label">Blood Group</label>
                                                    <p className="text-disabled">{state && state.qrCodeDetails.Blood_Group}</p>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <label className="form-label">Allergies / Comment</label>
                                                    <p className="text-disabled">{state && state.qrCodeDetails.Allergies_Comment}</p>
                                                </div>
                                            </Col>
                                            
                                            <Col md={12}>
                                                <div className="mb-1 pt-2">
                                                    <button className="btn btn-outline-primary btn-rounded w-100" onClick={()=>navigate("/welcome-qr", {state: state})} >Cancel</button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        
        </main>
    </React.Fragment>
    );
}
  
  export default Cart;