import React, {useState, useRef}from "react";
import { toast } from 'react-toastify';
import validator from 'validator';
import { Row, Col,Container,Breadcrumb, BreadcrumbItem  } from 'reactstrap';
import BusinessAssociateImg from './../../Assets/Images/program.png';
import  HeaderFooter from './../../HeaderFooter';
import classNames from 'classnames';
import { checkEmail, postDocumentForMerchant, registrationPost } from "../../utility/service";
import {useDispatch} from "react-redux"
import { LoaderForIndividualPageDispatch } from "../../Components/Loader/LoaderForIndividualPage.slice.reducer";
import { ImageCompressor } from 'image-compressor';
import { checkMobileWebView } from "../../utility/helper";
const imageCompressor = new ImageCompressor;

const compressorSettings = {
    toWidth : 800,
    // toHeight : 500,
    mimeType : 'image/png',
    mode : 'strict',
    quality : 0.9,
    // grayScale : true,
    // sepia : true,
    // threshold : 127,
    vReverse : false,
    hReverse : false,
    speed : 'low'
};

function BusinessAssociate() {
    
    const formRef = useRef(null);
    const dispatch= useDispatch();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [city, setCity] = useState("");
    const [mobileNumber, setMobileNumber] = useState("")
    const [email, setEmail] = useState("")
    const [employeeCode, setEmployeeCode] = useState("");
    const [address, setAddress] = useState("");
    const [amount, setAmount] = useState("");
    const [bankName, setBankName] = useState("");
    const [IFSC, setIFSC] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [accountHolderName, setAccountHolderName] = useState("");
    const [accountType, setAccountType] = useState("");
    
    const [IDProof, setIDProof] = useState(null);
    const [resident, setResident] = useState(null);
    const [businessDocument, setBusinessDocument] = useState(null);
    const [selfie, setSelfie] = useState(null);
    const [documetIDs, setDocumetIDs] = useState("");
    
    const [firstNameErr, setrFirstNameErr] = useState(false)
    const [lastNameErr, setLastNameErr] = useState(false)
    const [cityErr, setCityErr] = useState(false)
    const [mobileNumberErr, setMobileNumberErr] = useState(false)
    const [emailErr, setEmailErr] = useState(false)
    const [employeeCodeErr, setEmployeeCodeErr] = useState(false)
    const [addressErr, setAddressErr] = useState(false)
    const [amountErr, setAmountErr] = useState(false)
    const [bankNameErr, setBankNameErr] = useState(false)
    const [IFSCErr, setIFSCErr] = useState(false)
    const [accountNumberErr, setAccountNumberErr] = useState(false)
    const [accountHolderNameErr, setAccountHolderNameErr] = useState(false)
    const [accountTypeErr, setAccountTypeErr] = useState(false)

    const [IDProofErr, setIDProofErr] = useState(false);
    const [residentErr, setResidentErr] = useState(false);
    const [businessDocumentErr, setBusinessDocumentErr] = useState(false);
    const [selfieErr, setSelfieErr] = useState(false);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];

        if(file && file.size && ((file.size / (1024 * 1024)) > 10)){
            toast.dismiss();
            toast("File size limited to 10MB");
            return;
        }
        
        if (file) {
            const reader = new FileReader();
            
            reader.onload = (e) => {
                const base64String = e.target.result;

                if(event.target.name === "IDProof"){
                    if(file && file.size && ((file.size / (1024 * 1024)) > 2)){
                        setIDProof((pre)=>{
                            return{
                                ...pre,
                                actualFileName: file.name
                            }
                        })
                        imageCompressor.run(base64String, compressorSettings, proceedCompressedImageIDProof);
                    }else{
                        setIDProof(pre=>{
                            return{
                                    ...pre,
                                FileName: base64String,
                                Key: "Document1",
                                roleId : 4,
                                actualFileName: file.name
                            }
                        })
                    }
                   
                }
                else if(event.target.name === "resident"){
                    if(file && file.size && ((file.size / (1024 * 1024)) > 2)){
                        setResident((pre)=>{
                            return{
                                ...pre,
                                actualFileName: file.name
                            }
                        })
                        imageCompressor.run(base64String, compressorSettings, proceedCompressedImageResident);
                    }else{
                        setResident(pre=>{
                            return{
                                    ...pre,
                                FileName: base64String,
                                Key: "Document2",
                                roleId : 4,
                                actualFileName: file.name
                            }
                        })
                    }
                }
                else if(event.target.name === "businessDocument"){
                    if(file && file.size && ((file.size / (1024 * 1024)) > 2)){
                        setBusinessDocument((pre)=>{
                            return{
                                ...pre,
                                actualFileName: file.name
                            }
                        })
                        imageCompressor.run(base64String, compressorSettings, proceedCompressedImageBusinessDocument);
                    }else{
                        setBusinessDocument(pre=>{
                            return{
                                ...pre,
                                FileName: base64String,
                                Key: "Document3",
                                roleId : 4,
                                actualFileName: file.name
                            }
                        })
                    }
                }
                else if(event.target.name === "selfie"){
                    if(file && file.size && ((file.size / (1024 * 1024)) > 2)){
                        setSelfie((pre)=>{
                            return{
                                ...pre,
                                actualFileName: file.name
                            }
                        })
                        imageCompressor.run(base64String, compressorSettings, proceedCompressedImageSelfie);
                    }else{
                        setSelfie(pre=>{
                            return{
                                ...pre,
                                FileName: base64String,
                                Key: "Selfie",
                                roleId : 4,
                                actualFileName: file.name
                            }
                        })
                    }
                }
            };
            
            reader.readAsDataURL(file);
        }
    };  
    

    const proceedCompressedImageIDProof = (compressedSrc) => {
        setIDProof(pre=>{
            return{
                    ...pre,
                FileName: compressedSrc,
                Key: "Document1",
                roleId : 4
            }
        })
    }
    const proceedCompressedImageResident = (compressedSrc) => {
        setResident(pre=>{
            return{
                    ...pre,
                FileName: compressedSrc,
                Key: "Document2",
                roleId : 4
            }
        })
    }
    const proceedCompressedImageBusinessDocument = (compressedSrc) => {
        setBusinessDocument(pre=>{
            return{
                    ...pre,
                FileName: compressedSrc,
                Key: "Document3",
                roleId : 4
            }
        })
    }
    const proceedCompressedImageSelfie = (compressedSrc) => {
        setSelfie(pre=>{
            return{
                    ...pre,
                FileName: compressedSrc,
                Key: "Selfie",
                roleId : 4
            }
        })
    }

    const selectedValue = (e) =>{
    
        switch(e.target.name){
            case "amount":
                setAmount(e.target.value);
                setAmountErr(false);
                break;
            case "accountType":
                setAccountType(e.target.value);
                setAccountTypeErr(false);
                break;
            }
    }

    const onInputChange = (e)=>{
    
        switch(e.target.name){
            case "firstName":
                setFirstName(e.target.value.replace(/[^a-z]/gi, ''));
                setrFirstNameErr(false)
            break;
            case "lastName":
                setLastName(e.target.value.replace(/[^a-z]/gi, ''));
                setLastNameErr(false)
            break;
            case "city":
                setCityErr(false)
                setCity(e.target.value);
            break;
            case "mobileNumber":
                if(/^\d{0,12}$/.test((e.target.value))){
                    setMobileNumberErr(false)
                    setMobileNumber(e.target.value);
                }
            break;
            case "email":
                setEmail(e.target.value.trim());
                setEmailErr(false)
                break;
            case "employeeCode":
                setEmployeeCode(e.target.value.trim());
                setEmployeeCodeErr(false)
                break;
            case "address":
                setAddressErr(false)
                setAddress(e.target.value);
            break;
            case "bankName":
                setBankNameErr(false)
                setBankName(e.target.value);
            break;
            case "IFSC":
                setIFSCErr(false)
                setIFSC(e.target.value);
            break;
            case "accountNumber":
                if(/^\d{0,18}$/.test((e.target.value))){
                    setAccountNumberErr(false)
                    setAccountNumber(e.target.value);
                }
               
            break;
            case "accountHolderName":
                setAccountHolderNameErr(false)
                setAccountHolderName(e.target.value);
            break;
        }
    }


    const applyPay= async(e)=>{

        e.preventDefault();
        toast.dismiss();

        if(firstName!== undefined && validator.isEmpty(firstName.trim())){
            toast.error("Please Enter First Name", { });
            setrFirstNameErr(true)
            return;
        }else if(firstName!== undefined && !validator.isLength(firstName.trim(), {min: 1, max: 25})){
            toast.error("First Name length should be minimum 3 char and maximum 25 char", { });
            setrFirstNameErr(true)
            return;
        }
         
        if(lastName!== undefined && validator.isEmpty(lastName.trim())){
            toast.error("Please Enter Last Name", { });
            setLastNameErr(true)
            return;
        }else if(lastName!== undefined && !validator.isLength(lastName.trim(), {min: 1, max: 25})){
            toast.error("Last Name length should be minimum 3 char and maximum 25 char", { });
            setLastNameErr(true)
            return;
        }
        
        if(validator.isEmpty(city.trim())){
            toast.error("Please Enter City Name", { });
            setCityErr(true)
            return;
        }

        if(validator.isEmpty(mobileNumber)){
            toast.error("Please Enter Mobile Number", { });
            setMobileNumberErr(true)
            return;
        }

        if(!validator.isEmail(email.trim())){
            setEmailErr(true)
            if(email === "") toast.error("Please Enter Email", { });
            else toast.error("Please Enter valid Email", { });
            setEmailErr(true)
            return
        }

        if(employeeCode!== undefined && validator.isEmpty(employeeCode.trim())){
            toast.error("Please Enter Employee Code", { });
            setEmployeeCodeErr(true)
            return;
        }

        if(validator.isEmpty(address.trim())){
            toast.error("Please Enter address Name", { });
            setAddressErr(true)
            return;
        } 
       
        if(validator.isEmpty(amount.trim())){
            toast.error("Please Select Amount", { });
            setAmountErr(true)
            return;
        } 
       
        if(validator.isEmpty(bankName.trim())){
            toast.error("Please Bank Name", { });
            setBankNameErr(true)
            return;
        } 
       
        if(validator.isEmpty(IFSC.trim())){
            toast.error("Please IFSC Code", { });
            setIFSCErr(true)
            return;
        }else if (!/^[A-Z]{4}[0-9]{7}$/.test(IFSC.trim())) {
            toast.error("Please Enter Valid IFSC Code", { });
            setIFSCErr(true);
            return false;
          } 
        
        if(validator.isEmpty(accountNumber.trim()) && parseInt(accountNumber).length !== 10){
            toast.error("Please Account Number", { });
            setAccountNumberErr(true)
            return;
        }else if(accountNumber!== undefined && !validator.isLength(accountNumber.trim(), {min: 10, max: 25})){
            toast.error("Please Enter Valid Account Number", { });
            setAccountNumberErr(true)
            return;
        } 
        
        if(validator.isEmpty(accountHolderName.trim())){
            toast.error("Please Account Holder Name", { });
            setAccountHolderNameErr(true)
            return;
        } 
       
        if(validator.isEmpty(accountType.trim())){
            toast.error("Please Select Account Type", { });
            setAccountTypeErr(true)
            return;
        } 
        

        if(IDProof === null){
            toast.error("Please Select IDProof", { });
            setIDProofErr(true)
            return;
        } 
        if(resident === null){
            toast.error("Please Select Resident", { });
            setResidentErr(true)
            return;
        } 
        if(businessDocument === null){
            toast.error("Please Select Account Business Document", { });
            setBusinessDocumentErr(true)
            return;
        } 
        if(selfie === null){
            toast.error("Please Select selfie", { });
            setSelfieErr(true)
            return;
        } 

        let bussinessAsociationData = {
            firstName: firstName,
            lastName: lastName,
            city: city,
            mobileNumber: mobileNumber,
            email: email,
            employeeCode: employeeCode,
            address: address,
            amount: amount,
            bankName: bankName,
            IFSC: IFSC,
            accountNumber: accountNumber,
            accountHolderName: accountHolderName,
            accountType: accountType,
            IDProof: IDProof,
            resident: resident,
            businessDocument: businessDocument,
            selfie: selfie,
          } 
          
        try{
            let res= await checkEmail(bussinessAsociationData, dispatch); 

            if( res && res.Status ){
                console.log("res.HTTPStatusCoderes.HTTPStatusCode", res.HTTPStatusCode, 400)
                if(res!=undefined && res.HTTPStatusCode!= undefined && res.HTTPStatusCode === 400){
                    toast.error(res.Error.Message, { });
                    return;
                } 
            }

            let postDocumentRes =await postDocumentForMerchant([ 
                IDProof,
                resident,
                businessDocument,
                selfie,
            ], dispatch);
            
            dispatch(LoaderForIndividualPageDispatch(true))
            
            setDocumetIDs(JSON.stringify(postDocumentRes))
            setTimeout(() => {
                formRef.current.submit();
            }, 1000);
        }catch (err){
            if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
            return;
        }

    }

  
    return (
      <React.Fragment>
          <HeaderFooter>
           <div data-aos="zoom-in" data-aos-delay="200" data-aos-duration="200">
                <img src={BusinessAssociateImg} className="w-100" alt="BusinessAssociate" />
           </div>

           <Container>
                <div className="my-4 pt-0 pt-md-4">
                    
                    <Row className="justify-content-center">
                        <Col lg={10} xxl={8}>
                            <Breadcrumb> 
                                <BreadcrumbItem>                            <a 
                                href= {checkMobileWebView()? "#" :"/"}
                                onClick={(e)=>{
                                    if(checkMobileWebView()){
                                        e.preventDefault();
                                        window.ReactNativeWebView.postMessage('formSubmitted');
                                    }
                                }}
                            >Home</a></BreadcrumbItem>
                                <BreadcrumbItem active>Business Associate Program</BreadcrumbItem>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </div>
           </Container>
          
           <div className=" main-content">
          
            <Container>
                <div data-aos="zoom-in" data-aos-delay="200" data-aos-duration="200">
                    
                    <Row className="justify-content-center">
                        <Col lg={10} xxl={8}>
                            <h3 className="text-center text-black mb-4">Business Associate Program</h3>
                            <div className="white-box borderd">
                            <form className="p-1 p-md-3" onSubmit={applyPay}>
                                <Row className="justify-content-center">
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <label className="form-label">First Name</label>
                                            <input 
                                                type="text" 
                                                name="firstName" 
                                                placeholder="Enter your first name" 

                                                value={firstName} 
                                                onChange={onInputChange}
                                                className={classNames('form-control', { "form-error": firstNameErr })}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <label className="form-label">Last Name</label>
                                            <input  
                                                type="text" 
                                                name="lastName" 
                                                placeholder="Enter your last name" 

                                                value={lastName}
                                                onChange={onInputChange}  
                                                className={classNames('form-control', { "form-error": lastNameErr })}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <label className="form-label">City</label>
                                            <input 
                                                type="text" 
                                                name="city" 
                                                placeholder="Enter your city"

                                                value={city} 
                                                onChange={onInputChange}
                                                className={classNames('form-control', { "form-error": cityErr })}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <label className="form-label">Mobile</label>
                                            
                                            <input
                                                type="text" 
                                                className="form-control"
                                                placeholder="Enter your mobile number" 
                                                
                                                name="mobileNumber" 
                                                value={mobileNumber}
                                                onChange={onInputChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <label className="form-label">Email id</label>
                                            <input 
                                                type="text" 
                                                name="email" 
                                                placeholder="Enter your email id"

                                                value={email} 
                                                onChange={onInputChange}  
                                                className={classNames('form-control', { "form-error": emailErr })} 
                                            />
                                        </div>
                                    </Col>

                                    <Col md={6}>
                                        <div className="mb-3">
                                            <label className="form-label">Employee code</label>
                                            <input 
                                                type="text" 
                                                placeholder="Enter your employee code"

                                                name="employeeCode" 
                                                value={employeeCode} 
                                                onChange={onInputChange}
                                                className={classNames('form-control', { "form-error": employeeCodeErr })}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <label className="form-label">Address</label>
                                            <input 
                                                type="text" 
                                                name="address" 
                                                placeholder="Enter your address"

                                                value={address} 
                                                onChange={onInputChange}  
                                                className={classNames('form-control', { "form-error": addressErr })} 
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <label className="form-label">Amount</label>
                                            <select className="form-control" id="state" name="amount" onChange={selectedValue}>
                                                <option value="" selected= {amount === ""? true: false}>Select Amount</option>
                                                <option value="599" selected= {amount === "599"? true: false}>599</option>
                                            </select>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <label className="form-label">Bank name</label>
                                            <input 
                                                type="text" 
                                                placeholder="Enter your bank name"
                                                
                                                name="bankName" 
                                                value={bankName} 
                                                onChange={onInputChange}  
                                                className={classNames('form-control', { "form-error": bankNameErr })} 
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <label className="form-label">IFSC</label>
                                            <input 
                                                type="text" 
                                                placeholder="Enter your IFSC code"

                                                name="IFSC" 
                                                value={IFSC} 
                                                onChange={onInputChange}  
                                                className={classNames('form-control', { "form-error": IFSCErr })} 
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <label className="form-label">Account number</label>
                                            <input 
                                                type="text" 
                                                placeholder="Enter your account number"

                                                name="accountNumber" 
                                                value={accountNumber} 
                                                onChange={onInputChange}  
                                                className={classNames('form-control', { "form-error": accountNumberErr })} 
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <label className="form-label">Account holder</label>
                                            <input 
                                                type="text" 
                                                placeholder="Enter your account holder name"

                                                name="accountHolderName" 
                                                value={accountHolderName} 
                                                onChange={onInputChange}  
                                                className={classNames('form-control', { "form-error": accountHolderNameErr })} 
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <label className="form-label">Account type</label>
                                            <select className="form-control" id="type" name="accountType" onChange={selectedValue}>
                                                <option value="" selected= {accountType === ""? true: false}>Select type</option>
                                                <option value="Savings" selected= {accountType === "savings"? true: false}>Savings</option>
                                                <option value="Current" selected= {accountType === "current"? true: false}>Current</option>
                                            </select>
                                        </div>
                                    </Col>


                                    <Col className="mb-3" md={6}>
                                        <div className="uploadFile">
                                                <label for="id">ID Proof</label>
                                                <span>
                                                    <input 
                                                        type="file" 
                                                        id="id" 
                                                        className='form-control' 
                                                        
                                                        name="IDProof" 
                                                        onChange={handleImageUpload}  
                                                        accept=".jpg, .jpeg, .png"
                                                    />   
                                                    <label for="id" className="upload-label text-disabled">
                                                        {IDProof !== null ? IDProof.actualFileName: "ID Proof"}
                                                    </label>
                                                </span>
                                                
                                        </div>
                                    </Col>
                                    <Col className="mb-3" md={6}>
                                        <div className="uploadFile">
                                                <label for="resident">Resident Proof</label>
                                                <span>
                                                    <input 
                                                        type="file" 
                                                        id="resident" 
                                                        className='form-control' 
                                                    
                                                        name="resident"
                                                        onChange={handleImageUpload}  
                                                        accept=".jpg, .jpeg, .png"
                                                    />      
                                                    <label for="resident" className="upload-label text-disabled">
                                                        {resident !== null ? resident.actualFileName: "Resident Proof"}
                                                    </label>
                                                </span>
                                        </div>
                                    </Col>
                                    <Col className="mb-3" md={6}>
                                        <div className="uploadFile">
                                            <label for="business-document">Business Document</label>
                                            <span>
                                                <input 
                                                    type="file" 
                                                    id="business-document" 
                                                    className='form-control'
                                                    
                                                    name="businessDocument"
                                                    onChange={handleImageUpload}  
                                                    accept=".jpg, .jpeg, .png"
                                                    
                                                />      
                                                <label for="business-document" className="upload-label text-disabled">
                                                    {businessDocument !== null ? businessDocument.actualFileName: "Business Document"}
                                                </label>
                                            </span>
                                                
                                        </div>
                                    </Col>
                                    <Col className="mb-3" md={6}>
                                        <div className="uploadFile">
                                            <label for="selfie">Upload Selfie</label>
                                            <span>
                                                <input 
                                                    type="file" 
                                                    id="selfie" 
                                                    className='form-control' 
                                                
                                                    name="selfie"
                                                    onChange={handleImageUpload}  
                                                    accept=".jpg, .jpeg, .png"
                                                />
                                                <label for="selfie" className="upload-label text-disabled">
                                                    {selfie !== null ? selfie.actualFileName: "Upload Selfie"}
                                                </label>
                                            </span>
                                        </div>
                                    </Col>
                                    
                                    <Col md={12}>
                                        <div className="mb-1 mt-4">
                                            <button className="btn btn-primary me-3 long-btn" type= "submit">Pay</button>
                                            <button className="btn btn-link" type= "button">Cancel</button>
                                        </div>
                                    </Col>
                                    </Row>
                            </form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            </div>
            </HeaderFooter>


            <form 
                ref={formRef} 
                style={{display: "none"}} 
                method="post" id="myForm" 
                name="myForm" 
                action=" https://admin.intellotags.com/Home/OnlinePayment" 
                class="ng-pristine ng-valid"
            >
                amount:<input 
                type="text" 
                ng-model="dsaStringData"
                // value="{&quot;id&quot;:0,&quot;firstname&quot;:&quot;merchanta&quot;,&quot;lastname&quot;:&quot;ppp&quot;,&quot;email&quot;:&quot;s100@yopmail.com&quot;,&quot;Mobile&quot;:&quot;8305430349&quot;,&quot;Address&quot;:&quot;Indore&quot;,&quot;City&quot;:&quot;Indore&quot;,&quot;EmployeeCode&quot;:&quot;SALSALDEF1084&quot;,&quot;CommissionPercentage&quot;:&quot;&quot;,&quot;Amount&quot;:&quot;599&quot;,&quot;BankDetails&quot;:{&quot;BankName&quot;:&quot;ICICI&quot;,&quot;AccountNumber&quot;:&quot;1400001234560&quot;,&quot;IFSC&quot;:&quot;SBIN0006880&quot;,&quot;AccountType&quot;:&quot;Savings&quot;,&quot;AcountHolderName&quot;:&quot;vaishnav&quot;}}" 
                name="dataModel" 
                class="ng-pristine ng-valid" 
                fdprocessedid="97y1h" 
                value ={JSON.stringify({
                    id: 0,
                    firstname: firstName,
                    lastname: lastName,
                    City: city,
                    Mobile: mobileNumber,
                    email: email,
                    EmployeeCode: employeeCode,
                    CommissionPercentage: "",
                    Address: address,
                    Amount: amount,
                    BankDetails: {
                        BankName: bankName,
                        AccountNumber: accountNumber,
                        IFSC: IFSC,
                        AccountType: accountType,
                        AcountHolderName: accountHolderName,
                    },
                })}
                />
                <h3>Form 2</h3>
                
                <input name="StringIds" id="StringIds" type="text" value={documetIDs} fdprocessedid="isz0er" /> 

                phone:<input type="text" value={mobileNumber} name="phone" fdprocessedid="itxjohs" />
                <br />
                
                address_type: <input type="text" value={city} name="address_type" fdprocessedid="ac2yko" />
                <br />
                
                shipping_address:<input type="text" value={city} name="shipping_address" fdprocessedid="vrlwy3" /><br />
                
                pincode:<input type="text" value="" name="pincode" fdprocessedid="a7wn9h" />
                <br />
                
                customername:<input type="text" name="customername" value={firstName} fdprocessedid="ioqhzs" />
                <br />
                <br />
                <br />
                
                billing_address:<input type="text" value={city} name="billing_address" id="billing_address" placeholder="billing_address" fdprocessedid="v6a6ss" />
                
                billing_email: <input type="text" value={email} name="billing_email" id="billing_email" placeholder="billing_email" fdprocessedid="mrjh3f" />
                
                <input type="text" name="isRequestFromMobile" value="1" fdprocessedid="mq37se" />
                
                <input name="MerchaneOwnerUserID" id="MerchaneOwnerUserID" type="text" value="1" fdprocessedid="6vh36h" />
                <br />
                
                <button type="submit" class="btn btn-primary submitData" fdprocessedid="f1bg3f">Pay</button>
            </form>



      </React.Fragment>
    );
  }
  export default BusinessAssociate;