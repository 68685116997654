import React, {useState} from "react";
import {Container, Row, Col} from 'reactstrap';
import Logo from './../../../../Assets/Images/logo.png';
import View from './../../../../Assets/Images/eye-primary.png';
import Delete from './../../../../Assets/Images/delete-red.png';
import Document from './../../../../Assets/Images/post/4.VirArjun-29.4.2023.jpeg';
import Document2 from './../../../../Assets/Images/sample.pdf';
import { toast } from 'react-toastify';
import { activeTag, isUserAvailiable, postDocument } from "../../../../utility/service";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux"
import { useNavigate } from 'react-router-dom'
import validator from 'validator';
import { ImageCompressor } from 'image-compressor';
import { checkMobileWebView } from "../../../../utility/helper";
const imageCompressor = new ImageCompressor;

const compressorSettings = {
    toWidth : 800,
    // toHeight : 500,
    mimeType : 'image/png',
    mode : 'strict',
    quality : 0.9,
    // grayScale : true,
    // sepia : true,
    // threshold : 127,
    vReverse : false,
    hReverse : false,
    speed : 'low'
};

function Cart() {
    
    const navigate = useNavigate()
    const dispatch= useDispatch();
    const {state} = useLocation();


    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [gender, setGender] = useState("")
    const [contactNumber, setContactNumber] = useState("")
    const [emergenciesContactNumber, setEmergenciesContactNumber] = useState("")
    const [address, setAddress] = useState("");
    const [blodGroup, setBlodGrop] = useState("");
    const [elagiesComent, setElagiesComent] = useState("");
    const [dob, setDob] = useState("")

    const [documentOne, setDocumentOne] = useState({
        DocumentType: "",
        DocumentTypeID: "",
        ExpiredDate: "",
        FileName: "",
        QRID: state.qrCode,
        actualFileName: ""
    });
    const [documentTwo, setDocumentTwo] = useState({
        DocumentType: "",
        DocumentTypeID: "",
        ExpiredDate: "",
        FileName: "",
        QRID: state.qrCode,
        actualFileName: ""
    });
    const [documentThree, setDocumentThree] = useState({
        DocumentType: "",
        DocumentTypeID: "",
        ExpiredDate: "",
        FileName: "",
        QRID: state.qrCode,
        actualFileName: ""
    });

    const [firstNameErr, setrFirstNameErr] = useState(false)
    const [lastNameErr, setLastNameErr] = useState(false)
    const [emailNameErr, setEmailNameErr] = useState(false)
    const [genderErr, setGenderErr] = useState(false)
    const [contactNumberErr, setContactNumberErr] = useState(false)
    const [emergenciesContactNumberErr, setEmergenciesContactNumberErr] = useState(false)
    const [addressErr, setAddressErr] = useState(false)
    const [blodGroupErr, setBlodGropErr] = useState(false);
    const [elagiesComentErr, setElagiesComentErr] = useState(false);
    const [dobErr, setDobErr] = useState(false)
    const [isEmailVerified, setIsEmailVerified] = useState(false)


    const applyActivateTag= async(e)=>{

        e.preventDefault();
        toast.dismiss();
        
        if(!validator.isEmail(email, {blacklisted_chars : "+-"})){
            if(email === "") toast.error("Please Enter Valid Email and verify first", { });
            else toast.error("Please Enter Valid Email and verify first", { });
            setEmailNameErr(true)
            return
        }else if(!isEmailVerified){
            toast.error("Please Sign Up And Re-scan the QR to Activate the Tag", { });
            return
        }

        if(firstName!== undefined && validator.isEmpty(firstName.trim())){
            toast.error("Please Enter First Name", { });
            setrFirstNameErr(true)
            return;
        }

        if(firstName!== undefined && validator.isEmpty(firstName.trim())){
            toast.error("Please Enter First Name", { });
            setrFirstNameErr(true)
            return;
        }else if(firstName!== undefined && !validator.isLength(firstName.trim(), {min: 1, max: 25})){
            toast.error("First Name length should be minimum 3 char and maximum 25 char", { });
            setrFirstNameErr(true)
            return;
        }
         
        if(lastName!== undefined && validator.isEmpty(lastName.trim())){
            toast.error("Please Enter Last Name", { });
            setLastNameErr(true)
            return;
        }else if(lastName!== undefined && !validator.isLength(lastName.trim(), {min: 1, max: 25})){
            toast.error("Last Name length should be minimum 3 char and maximum 25 char", { });
            setLastNameErr(true)
            return;
        }

        if(validator.isEmpty(gender)){
            toast.error("Please Select gender", { });
            setGenderErr(true)
            return;
        }

        if(contactNumber ===null ||validator.isEmpty(contactNumber)){
            toast.error("Please Enter emergency Number", { });
            setContactNumberErr(true)
            return;
        }

        if(emergenciesContactNumber ===null ||validator.isEmpty(emergenciesContactNumber)){
            toast.error("Please Enter emergency Number", { });
            setEmergenciesContactNumberErr(true)
            return;
        }

        if(validator.isEmpty(address.trim())){
            toast.error("Please Enter address Name", { });
            setAddressErr(true)
            return;
        } 

        if(blodGroup!== undefined && validator.isEmpty(blodGroup.trim())){
            toast.error("Please Enter Blod Group Name", { });
            setBlodGropErr(true)
            return;
        }
      
        if(elagiesComent!== undefined && validator.isEmpty(elagiesComent.trim())){
            toast.error("Please Allergies / Comment", { });
            setElagiesComentErr(true)
            return;
        }

        if(validator.isEmpty(dob)){
            toast.error("Please Enter dob", { });
            setDobErr(true)
            return;
        }

        // let allDoc= {
        //     docOne: false,
        //     docTwo: false,
        //     docThree: false
        // }

        // if(!documentOne.DocumentTypeID && !documentOne.ExpiredDate && !documentOne.FileName){
        //     allDoc.docOne= true;
        // }else if(!documentOne.DocumentTypeID || !documentOne.ExpiredDate || !documentOne.FileName || !documentOne.QRID ){
        //     toast.error("Please Select All Required Field For Document 1", { });
        //     return
        // }
        
        // if(!documentTwo.DocumentTypeID && !documentTwo.ExpiredDate && !documentTwo.FileName){
        //     allDoc.docTwo= true;
        // }else if(!documentTwo.DocumentTypeID || !documentTwo.ExpiredDate || !documentTwo.FileName || !documentTwo.QRID ){
        //     toast.error("Please Select All Required Field For Document 2", { });
        //     return;
        // }
        
        // if(!documentThree.DocumentTypeID && !documentThree.ExpiredDate && !documentThree.FileName){
        //     allDoc.docThree= true;
        // }else if(!documentThree.DocumentTypeID || !documentThree.ExpiredDate || !documentThree.FileName || !documentThree.QRID ){
        //     toast.error("Please Select All Required Field For Document 3", { });
        // }

        let activateData = {
            FirstName: firstName,
            LastName: lastName,
            Email: email,
            Gender: gender,
            Contact_No: contactNumber,
            EmergencyNumber: emergenciesContactNumber,
            PhoneNumber: "",
            Address: address,
            Blood_Group: blodGroup,
            Allergies_Comment: elagiesComent,
            DOB: dob,
            QRId: state.qrCode
        } 
          
        try{
            
            console.log("activateDataactivateData", documentOne,
            documentTwo,
            documentThree)
            let check= await isUserAvailiable({email: activateData.Email}, dispatch); 
            console.log("res.HTTPStatusCoderes.HTTPStatusCode", check)

            if(check.Success.length === 0){
                toast("Please Sign Up And Re-scan the QR to Activate the Tag", { });
                return;
            }else{

                let res= await activeTag(activateData, dispatch); 

                // let newDocArr =[]
                
                // if(!allDoc.docOne){
                //     newDocArr.push(documentOne)
                // }
                // if(!allDoc.docTwo){
                //     newDocArr.push(documentTwo)
                // }
                // if(!allDoc.docThree){
                //     newDocArr.push(documentThree)
                // }

                // await postDocument(newDocArr, dispatch); 
                // console.log("resresresres1", res)
                
                if( res && res.Status && res.Status === "Success" ){
                    navigate('/tag-activated');
                }
            }
            
        }catch (err){
            if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
            return;
        }
    }

    const handleImageUpload = (event) => {

        const file = event.target.files[0];

        if(file && file.size && ((file.size / (1024 * 1024)) > 10)){
            toast.dismiss();
            toast("File size limited to 10MB");
            return;
        }
        
        if (file) {
            const reader = new FileReader();
            
            reader.onload = (e) => {
                
                const base64String = e.target.result;

                if(event.target.name === "documentOne"){
                    if(file && file.size && ((file.size / (1024 * 1024)) > 2)){
                        setDocumentOne((pre)=>{
                            return{
                                ...pre,
                                actualFileName: file.name
                            }
                        })
                        imageCompressor.run(base64String, compressorSettings, proceedCompressedImageDocumentOne);
                    }else{
                        setDocumentOne(pre=>{
                            return{
                                ...pre,
                                FileName: base64String,
                                actualFileName: file.name
                            }
                        })
                    }
                }
                else if(event.target.name === "documentTwo"){
                    if(file && file.size && ((file.size / (1024 * 1024)) > 2)){
                        setDocumentTwo((pre)=>{
                            return{
                                ...pre,
                                actualFileName: file.name
                            }
                        })
                        imageCompressor.run(base64String, compressorSettings, proceedCompressedImageDocumentTwo);
                    }else{
                        setDocumentTwo(pre=>{
                            return{
                                ...pre,
                                FileName: base64String,
                                actualFileName: file.name
                            }
                        })
                    }
                }
                else if(event.target.name === "documentThree"){
                    if(file && file.size && ((file.size / (1024 * 1024)) > 2)){
                        setDocumentThree((pre)=>{
                            return{
                                ...pre,
                                actualFileName: file.name
                            }
                        })
                        imageCompressor.run(base64String, compressorSettings, proceedCompressedImageDocumentThree);
                    }else{
                        setDocumentThree(pre=>{
                            return{
                                ...pre,
                                FileName: base64String,
                                actualFileName: file.name
                            }
                        })
                    }
                }

            };
            
            reader.readAsDataURL(file);
        }
    };  

    const proceedCompressedImageDocumentOne = (compressedSrc) => {
        setDocumentOne(pre=>{
            return{
                ...pre,
                FileName: compressedSrc
            }
        })
    }
    const proceedCompressedImageDocumentTwo = (compressedSrc) => {
        setDocumentTwo(pre=>{
            return{
                ...pre,
                FileName: compressedSrc
            }
        })
    }
    const proceedCompressedImageDocumentThree = (compressedSrc) => {
        setDocumentThree(pre=>{
            return{
                ...pre,
                FileName: compressedSrc
            }
        })
    }

    const onInputChange = async (e)=>{
    
        switch(e.target.name){
            case "firstName":
                setFirstName(e.target.value.replace(/[^a-z]/gi, ''));
                setrFirstNameErr(false)
            break;
            case "lastName":
                setLastName(e.target.value.replace(/[^a-z]/gi, ''));
                setLastNameErr(false)
            break;
            case "email":
                setEmail(e.target.value);
                
                if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,}))$/.test(e.target.value)){
                    let check= await isUserAvailiable({email: e.target.value}, dispatch); 
                    console.log("res.HTTPStatusCoderes.HTTPStatusCode", check)

                    if(check.Success.length === 0){
                        toast("Please Sign Up And Re-scan the QR to Activate the Tag", { });
                        setIsEmailVerified(false)
                        return;
                    }else{
                        setIsEmailVerified(true)
                    }
                }
                
                setEmailNameErr(false)
            break;
            case "contact-number":
                if(/^(?:\+?\d{0,15}|\d{0,16})$/.test((e.target.value))){
                    setContactNumber(e.target.value);
                    setContactNumberErr(false);
                }
            break;
            case "emergency-contact":
                if(/^(?:\+?\d{0,15}|\d{0,16})$/.test((e.target.value))){
                    setEmergenciesContactNumber(e.target.value);
                    setEmergenciesContactNumberErr(false);
                }
            break;
            case "address":
                setAddress(e.target.value);
                setAddressErr(false)
            break;
            case "allergies":
                setElagiesComent(e.target.value)
                setElagiesComentErr(false);
            break;
            case "blood-group":
                setBlodGrop(e.target.value)
                setBlodGropErr(false);
            break;
        }
    }
    
    const selectedValue = (e) =>{
        
        switch(e.target.name){
            case "gender":
                setGender(e.target.value);
                setGenderErr(false);
              break;
            case "docType1":
                setDocumentOne({...documentOne, DocumentTypeID: e.target.value, DocumentType: getDocumentTypeTextValue(e.target.value)})
            break;
            case "docType2":
                setDocumentTwo({...documentTwo, DocumentTypeID: e.target.value, DocumentType: getDocumentTypeTextValue(e.target.value)})
            break;
            case "docType3":
                setDocumentThree({...documentThree, DocumentTypeID: e.target.value, DocumentType: getDocumentTypeTextValue(e.target.value)})
            break;
          }
    }
    
    const getDocumentTypeTextValue= (val)=>{

        if(val == 1){
            return "Vehicle Insurance"
        }else if(val == 2){
            return "Vehicle Registration"
        }else if(val == 3){
            return "PUC Certificate"
        }else if(val == 4){
            return "Driving License"
        }else if(val == 5){
            return "Residence proof"
        }else if(val == 6){
            return "Id proof"
        }



    }

    const onDateChangeHandler = (e) =>{
    
        switch(e.target.name){
            case "personaldob":
                setDob(e.target.value);
                setDobErr(false);
            break;
            case "docType1expiryData":
                setDocumentOne({...documentOne, ExpiredDate: e.target.value});
                break;
            case "docType2expiryData":
                setDocumentTwo({...documentTwo, ExpiredDate: e.target.value});
            break;
            case "docType3expiryData":
                setDocumentThree({...documentThree, ExpiredDate: e.target.value});
            break;
        }
    }

    const getGenderView = (e) =>{
        return <><option value= "" selected= {gender !== ""? true: false}>Select gender</option>
            <option value= "M" selected= {gender === "M"? true: false}>Male</option>
            <option value="F" selected= {gender === "F"? true: false}>Female</option>
            <option value="O" selected= {gender === "O"? true: false}>Other</option></>
    }

    const getDocumentImgView = () =>{

        let doc1, doc2, doc3 = null;
        console.log("documentOnedocumentOne", documentOne);

        if(documentOne.FileName){
            doc1= <div className="d-flex align-items-center justify-content-between p-1 mb-1">
                <div className="d-flex align-items-center">
                    <img src={documentOne.FileName} alt="Document" className="rounded img-thumbnail me-2 me-sm-3" />
                    <p className="text-disabled m-0 text-truncate">{documentOne.actualFileName}</p>
                </div>
                <div className="d-flex align-items-center" onClick={()=>setDocumentOne({
                    DocumentType: "",
                    DocumentTypeID: "",
                    ExpiredDate: "",
                    FileName: "",
                    QRID: state.qrCode,
                    actualFileName: ""
                })}>
                    {/* <img src={View} alt="View" className="img-fluid ms-2 ms-sm-3 document-icon" /> */}
                    <img src={Delete} alt="Delete" className="img-fluid ms-2 ms-sm-3 document-icon" />
                </div>
            </div>
        }
        if(documentTwo.FileName){
            doc2= <div className="d-flex align-items-center justify-content-between p-1 mb-1">
                <div className="d-flex align-items-center">
                    <img src={documentTwo.FileName} alt="Document" className="rounded img-thumbnail me-2 me-sm-3" />
                    <p className="text-disabled m-0 text-truncate">{documentTwo.actualFileName}</p>
                </div>
                <div className="d-flex align-items-center" onClick={()=>setDocumentTwo({
                    DocumentType: "",
                    DocumentTypeID: "",
                    ExpiredDate: "",
                    FileName: "",
                    QRID: state.qrCode,
                    actualFileName: ""
                })}>
                    {/* <img src={View} alt="View" className="img-fluid ms-2 ms-sm-3 document-icon" /> */}
                    <img src={Delete} alt="Delete" className="img-fluid ms-2 ms-sm-3 document-icon" />
                </div>
            </div>
        }
        if(documentThree.FileName){
            doc3= <div className="d-flex align-items-center justify-content-between p-1 mb-1">
                <div className="d-flex align-items-center">
                    <img src={documentThree.FileName} alt="Document" className="rounded img-thumbnail me-2 me-sm-3" />
                    <p className="text-disabled m-0 text-truncate">{documentThree.actualFileName}</p>
                </div>
                <div className="d-flex align-items-center" onClick={()=>setDocumentThree({
                    DocumentType: "",
                    DocumentTypeID: "",
                    ExpiredDate: "",
                    FileName: "",   
                    QRID: state.qrCode,
                    actualFileName: ""
                })}>
                    {/* <img src={View} alt="View" className="img-fluid ms-2 ms-sm-3 document-icon" /> */}
                    <img src={Delete} alt="Delete" className="img-fluid ms-2 ms-sm-3 document-icon" />
                </div>
            </div>
        }

        return [doc1, doc2, doc3]

    }

    const getDocumentsTypeViewForDocType1 = (e) =>{
        return <><option value= "" selected= {documentOne.DocumentTypeID == ""? true: false}>Select Document type</option>
        <option value="1" selected= {documentOne.DocumentTypeID == "1"? true: false}>Vehicle Insurance</option>
        <option value="2" selected= {documentOne.DocumentTypeID == "2"? true: false}>Vehicle Registration</option>
        <option value="3" selected= {documentOne.DocumentTypeID == "3"? true: false}>PUC Certificate</option>
        <option value="4" selected= {documentOne.DocumentTypeID == "4"? true: false}>Driving License</option>
        <option value="5" selected= {documentOne.DocumentTypeID == "5"? true: false}>Residence proof</option>
        <option value="6" selected= {documentOne.DocumentTypeID == "6"? true: false}>Id proof</option></>
    }
    const getDocumentsTypeViewForDocType2 = (e) =>{
        return <><option value= "" selected= {documentTwo.DocumentTypeID == ""? true: false}>Select Document type</option>
        <option value="1" selected= {documentTwo.DocumentTypeID == "1"? true: false}>Vehicle Insurance</option>
        <option value="2" selected= {documentTwo.DocumentTypeID == "2"? true: false}>Vehicle Registration</option>
        <option value="3" selected= {documentTwo.DocumentTypeID == "3"? true: false}>PUC Certificate</option>
        <option value="4" selected= {documentTwo.DocumentTypeID == "4"? true: false}>Driving License</option>
        <option value="5" selected= {documentOne.DocumentTypeID == "5"? true: false}>Residence proof</option>
        <option value="6" selected= {documentOne.DocumentTypeID == "6"? true: false}>Id proof</option></>
    }
    const getDocumentsTypeViewForDocType3 = (e) =>{
        return <><option value= "" selected= {documentThree.DocumentTypeID == ""? true: false}>Select Document type</option>
        <option value="1" selected= {documentThree.DocumentTypeID == "1"? true: false}>Vehicle Insurance</option>
        <option value="2" selected= {documentThree.DocumentTypeID == "2"? true: false}>Vehicle Registration</option>
        <option value="3" selected= {documentThree.DocumentTypeID == "3"? true: false}>PUC Certificate</option>
        <option value="4" selected= {documentThree.DocumentTypeID == "4"? true: false}>Driving License</option>
        <option value="5" selected= {documentOne.DocumentTypeID == "5"? true: false}>Residence proof</option>
        <option value="6" selected= {documentOne.DocumentTypeID == "6"? true: false}>Id proof</option></>
    }
    
    return (
        <React.Fragment>
            <main className="bg-light qr-screen h-100vh">
                <header className="header px-3 px-sm-4">
                    <div className="container">
                        <nav class="fixed-top1 navbar-expand-lg navbar navbar-light bg-faded">
                                                        <a 
                                href= {checkMobileWebView()? "#" :"/"}
                                onClick={(e)=>{
                                    if(checkMobileWebView()){
                                        e.preventDefault();
                                        window.ReactNativeWebView.postMessage('formSubmitted');
                                    }
                                }}
                            >
                                <img src={Logo} alt="Logo" className="img-fluid mt-0" />
                            </a>
                        </nav>
                    </div>
                </header>
                <section>
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col xl={6}>
                                <div className="mb-2 ">
                                    <h6 className="light-title text-uppercase text-primary">Welcome to</h6>
                                    <h3 class="text-uppercase text-primary">Intello Tags</h3>
                                    <p className="mw-250 font-medium mb-0">Your gateway to exemplary services.</p>
                                </div>
                                <div className="qr-from text-start p-3 p-sm-4">
                                    <div className="white-box px-1">
                                        <h6 className="text-center pt-2">Fill the form</h6>
                                        <form className="pt-3" onSubmit={applyActivateTag}>
                                            <div className="px-3">
                                            <Row>
                                                <Col className="mb-3" lg={6}>
                                                    <label>First Name</label>
                                                    <input 
                                                        type="text" 
                                                        className='form-control' 
                                                        name="firstName" 
                                                        value={firstName}
                                                        onChange={onInputChange}
                                                        placeholder="Enter first Name" 
                                                    />
                                                </Col>
                                            
                                                <Col className="mb-3" lg={6}>
                                                    <label>Last Name</label>
                                                    <input 
                                                        type="text" 
                                                        className='form-control' 
                                                        name="lastName" 
                                                        value={lastName}
                                                        onChange={onInputChange}
                                                        placeholder="Enter last Name" 
                                                    />
                                                </Col>
                                            
                                                <Col className="mb-3" lg={6}>
                                                    <label>Email</label>
                                                    <input 
                                                        type="text" 
                                                        className='form-control' 
                                                        name="email" 
                                                        value={email}
                                                        onChange={onInputChange}
                                                        placeholder="Enter email" 
                                                    />
                                                </Col>
                                                
                                                <Col className="mb-3" lg={6}>
                                                    <label>Gender</label>
                                                    <select className="form-control" id="gender" name="gender" onChange={selectedValue}>
                                                        {getGenderView()}
                                                    </select>
                                                </Col>
                                                
                                                <Col className="mb-3" lg={6}>
                                                    <label>Contact no.</label>
                                                    <input 
                                                        type="phone" 
                                                        className='form-control' 
                                                        name="contact-number" 
                                                        value={contactNumber}
                                                        onChange={onInputChange}
                                                        placeholder="Enter mobile no." 
                                                    />
                                                </Col>

                                                <Col className="mb-3" lg={6}>
                                                    <label>Emergency contact no.</label>
                                                    <input 
                                                        type="phone" 
                                                        className='form-control' 
                                                        name="emergency-contact" 
                                                        value={emergenciesContactNumber}
                                                        onChange={onInputChange}
                                                        placeholder="Enter emergency contact no." 
                                                    />
                                                </Col>

                                                
                                                <Col className="mb-3" lg={6}>
                                                    <label>Address</label>
                                                    <input 
                                                        type="text" 
                                                        className='form-control' 
                                                        name="address" 
                                                        value={address}
                                                        onChange={onInputChange}
                                                        placeholder="Enter your address" 
                                                    />
                                                </Col>

                                                <Col className="mb-3" lg={6}>
                                                    <label>Blood Group</label>
                                                    <input 
                                                        type="text" 
                                                        className='form-control' 
                                                        name="blood-group"
                                                        value={blodGroup}
                                                        onChange={onInputChange} 
                                                        placeholder="Enter Blood Group" 
                                                    />
                                                </Col>
                                                
                                                <Col className="mb-3" lg={6}>
                                                    <label>Allergies / Comment</label>
                                                    <input 
                                                        type="text" 
                                                        className='form-control' 
                                                        name="allergies" 
                                                        value={elagiesComent}
                                                        onChange={onInputChange}
                                                        placeholder="Blood Pressor, Sugar" 
                                                    />
                                                </Col>
                                            
                                                <Col className="mb-3" lg={6}>
                                                    <label>DOB</label>
                                                    <input 
                                                        type="date" 
                                                        className='form-control' 
                                                        name="personaldob" 
                                                        value={dob}
                                                        onChange={onDateChangeHandler}
                                                        placeholder="DD/MM/YY" 
                                                    />
                                                </Col>

                                            </Row> 
                                            </div>

                                            {/* <div className="px-3">
                                                <label>(optional)</label>
                                                <Row>
                                                    <Col className="mb-3" md={4}>
                                                        <label>Expiry Date</label>
                                                        <input 
                                                            type="date" 
                                                            className='form-control' 
                                                            name="docType1expiryData" 
                                                            placeholder="DD/MM/YY" 
                                                            value={documentOne.ExpiredDate}
                                                            onChange={onDateChangeHandler}
                                                        />
                                                    </Col>
                                                    <Col className="mb-3" md={4}>
                                                        <label>Select Document Type</label>
                                                        <select className="form-control" id="gender" name="docType1" onChange={selectedValue}>
                                                            {getDocumentsTypeViewForDocType1()}
                                                        </select>
                                                    </Col>
                                                    <Col className="mb-3" md={4}>
                                                    <div className="uploadFile">
                                                        <label for="document1">Document 1</label>
                                                        <span>
                                                            <input 
                                                                type="file" 
                                                                id="document1" 
                                                                className='form-control' 
                                                                name="documentOne"
                                                                onChange={handleImageUpload}  
                                                                accept=".jpg, .jpeg, .png"
                                                            />   
                                                            <label for="document1" className="upload-label text-disabled">{documentOne && documentOne.actualFileName}</label>
                                                        </span>
                                                            
                                                    </div>
                                                    </Col>


                                                    <Col className="mb-3" md={4}>
                                                        <label>Expiry Date</label>
                                                        <input 
                                                            type="date" 
                                                            className='form-control' 
                                                            name="docType2expiryData" 
                                                            placeholder="DD/MM/YY" 
                                                            value={documentTwo.ExpiredDate}
                                                            onChange={onDateChangeHandler}
                                                        />
                                                    </Col>
                                                    <Col className="mb-3" md={4}>
                                                        <label>Select Document Type</label>
                                                        <select className="form-control" id="gender" name="docType2" onChange={selectedValue}>
                                                            {getDocumentsTypeViewForDocType2()}
                                                        </select>
                                                    </Col>
                                                    <Col className="mb-3" md={4}>
                                                    <div className="uploadFile">
                                                        <label for="document2">Document 2</label>
                                                        <span>
                                                            <input 
                                                                type="file" 
                                                                id="document2" 
                                                                className='form-control' 
                                                                name="documentTwo"
                                                                onChange={handleImageUpload}  
                                                                accept=".jpg, .jpeg, .png"
                                                            />   
                                                            <label for="document2" className="upload-label text-disabled">{documentTwo && documentTwo.actualFileName}</label>
                                                        </span>
                                                    </div>
                                                    </Col>


                                                    <Col className="mb-3" md={4}>
                                                        <label>Expiry Date</label>
                                                        <input 
                                                            type="date" 
                                                            className='form-control' 
                                                            name="docType3expiryData" 
                                                            placeholder="DD/MM/YY" 
                                                            value={documentThree.ExpiredDate}
                                                            onChange={onDateChangeHandler}
                                                        />
                                                    </Col>
                                                    <Col className="mb-3" md={4}>
                                                        <label>Select Document Type</label>
                                                        <select className="form-control" id="gender" name="docType3" onChange={selectedValue}>
                                                            {getDocumentsTypeViewForDocType3()}
                                                        </select>
                                                    </Col>
                                                    <Col className="mb-3" md={4}>
                                                        <div className="uploadFile">
                                                            <label for="document3">Document 3</label>
                                                            <span>
                                                                <input 
                                                                    type="file" 
                                                                    id="document3" 
                                                                    className='form-control' 
                                                                    name="documentThree"
                                                                    onChange={handleImageUpload}  
                                                                    accept=".jpg, .jpeg, .png" 
                                                                />   
                                                                <label for="document3" className="upload-label text-disabled">{documentThree && documentThree.actualFileName}</label>
                                                            </span>
                                                        </div>
                                                    </Col>
                                                   

                                                    <Col className="mt-2" md={12}>
                                                        <div className="document-lists">
                                                          {getDocumentImgView()}
                                                        </div>
                                                    </Col>

                                                </Row> 
                                            </div> */}

                                            <div className="px-4 py-2">
                                                <button type="submit" className="btn btn-primary btn-rounded w-100">Submit</button>
                                            </div>
                                        </form>
                                        
                                    </div>
                                </div>
                                
                            </Col>
                        </Row>
                    </Container>
                </section>
            
            </main>
        </React.Fragment>
    );
}
  
  export default Cart;