import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import './App.css';
import { HashRouter as Router} from "react-router-dom";
import AllRoutes from './Routes';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import LoaderImg from './Assets/Images/barcode.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

import 'bootstrap/dist/css/bootstrap.css';
import './Assets/Styles/Index.css';

// Import Swiper styles
import 'swiper/css';
import { setAuth } from "./Screen/Authentications/Login/slice.reducer";
import { profileDispatch } from "./Screen/Pro/profile.slice";
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux'
import { checkIsSecondWebview } from "./utility/helper";
import TabNavigation from "./Components/TabNavigation";

function App() {

  const dispatch = useDispatch();
  const [animationLoader, setAnimationLoader] = useState(true);
  const auth = useSelector(state => state.auth)
  const LoaderForIndividualPage = useSelector(state => state.LoaderForIndividualPage)
  const loader = useSelector(state => state.loader)
 
 
  console.log("AppApp", loader)
  useEffect(() => {
    AOS.init();
    async function getUserDetails(){
      if(auth.data === null){
        const data = await JSON.parse(localStorage.getItem('auth'))
        if( data !== null ){
          console.log("this is a login useEffect",data)
          // set auth slice with localStorage data
          dispatch(setAuth(data));
          dispatch(profileDispatch(data.ID))
        }
      }
    }
    getUserDetails();
  }, []);
  
  return (
    <Router>
      <div className={`App ${loader.open && 'loaderopen'}`}>
        {loader.open &&
            <div className="inner-loader">
              <span class="loader11 ">
                <img src={LoaderImg} alt="loader"  />
              </span>
          </div>
          }
        {LoaderForIndividualPage.open &&
            <div className="inner-loader">
            <span class="loader11 ">
              <img src={LoaderImg} alt="loader"  />
            </span>
        </div>
         }

        <div className="allContent">
          {ReactDOM.createPortal(
              <ToastContainer />,
              document.getElementById('toast-portal')
          )}
          
          <AllRoutes />
          {/* Bottom Tab Navigation (visible on all pages) */}
          {auth.data !== null && <TabNavigation />} 
        </div>
      </div>
    </Router>
  );
}

export default App;


// <!-- Yet to be completed -->
// - start samjgaya ex--> 3.5 -->3.5, 3.6 --> 4, 3.4 --> 3
// - image magnify issue 
// - getting 400 at time of add to cart
// - shipping form should be submitted after place order
// - product details --> related item at bottom


// 6ce12c0732
